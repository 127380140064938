import React from 'react';

import { ADD_KOALA_REDIRECT_URI } from '../utils/common';
import '../styling/base.css';
import { useTranslation } from 'react-i18next';

const AddKoala = () => {
  const { t } = useTranslation();
  return (
    <div className="content" data-testid="no-koala">
      <a href={ADD_KOALA_REDIRECT_URI} data-testid="redirect-uri">
        <img height="20%" width="20%" className="headerLogo" alt="koala_logo" src={require('../assets/img/KoalaBotLogo-min.png')} id="big-koala-icon" data-testid="header-logo" />
      </a>
      <a className="add-koala-text" data-testid="redirect-uri" href={ADD_KOALA_REDIRECT_URI}>
        {t('addkoala.add_koala')}
      </a>
      <p className="add-koala-text-small">{t('addkoala.koala_redirect')}</p>
    </div>
  );
};

export default AddKoala;
