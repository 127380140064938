import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styling/about.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faBullseye, faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

const About = () => {
  const { t } = useTranslation();

  return (
    <div data-testid="about" className="about">
      <header className="about_header">
        <h1>{t('about.about_us')}</h1>
      </header>
      <section className="about_section">
        <article className="about_article" id="a1">
          <FontAwesomeIcon className="about_article_icon" icon={faUsers} />
          <h1>{t('about.article_1_title')}</h1>
          <p>{t('about.article_1')}</p>
        </article>
        <article className="about_article" id="a2">
          <FontAwesomeIcon className="about_article_icon" icon={faBullseye} />
          <h1>{t('about.article_2_title')}</h1>
          <p>{t('about.article_2')}</p>
        </article>
        <article className="about_article" id="a3">
          <FontAwesomeIcon className="about_article_icon" icon={faQuoteLeft} />
          <h1>{t('about.article_3_title')}</h1>
          <p>{t('about.article_3')}</p>
        </article>
      </section>
    </div>
  );
};

export default About;
