import React, { useEffect, useState } from 'react';
import '../styling/home.css';
import '../styling/table.css';
import classNames from 'classnames';
import ReactLoading from 'react-loading';
import { isEqual } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const calculateItemsPerPage = (paginate, data) => {
  return paginate.amountsPerPage && paginate.enabled ? paginate.amountsPerPage[0] : data ? data.length : 0;
};

/** Some notes for the Table params
 *
 * @param tableId               tableId for reference (String)
 *                              Example: 'twitchAlertTable'
 * @param cols                  Column details { orderable: boolean, displayName: String, id: String, data: boolean }
 *                              Example: { orderable: true, displayName: 'Twitch Stream', id: 'twitchStream', data: true}
 *                              * cols.data refers to if the column contents is data or a set of HTML elements
 * @param data                  Row data to be inserted (Array containing objects which reference the column id)
 * @param paginate              (optional) Pagination details - { enabled: boolean, amountsPerPage: Array }
 *                              Example: { enabled: true, amountsPerPage: [5, 10, 20, 50]}
 * @param filter                (optional) Search filter details - { enabled: boolean, filterOnColumnId: String, text: String }
 *                              Example: { enabled: true, filterOnColumnId: 'twitchStream', text: 'Filter by stream...'}
 *                              * filter.filterOnColumnId refers to the column where the search will take place on
 * @param sensistiveDataFilter  (optional) Sensitive data filter details - { enabled: boolean, suffix: String, filteredColumns: Array }
 *                              Example: { enabled: true, suffix: '@herts.ac.uk', filteredColumns: ['twitchStream'] }
 *                              * sensistiveDataFilter.filteredColumns refers to the column id's which should be filtered
 * @param selectableRows        (optional) Option for rows to be selectable - { enabled: boolean, selectedRows: Array, selectId: String, onSelectRowClick: function, onSelectAllClick: function, actionButton: Button }
 *                              Example: { enabled: true, selectedRows: selectedRows, selectId: 'filterText', onSelectRowClick: updateSelectedRows(), onSelectAllClick: updateAllRows(), actionButton: <Button onClick=handleClick()/>}
 *                              * unique keys for checkboxes are determined from the text data of the first column
 */

const Table = ({
  tableId = 'CHANGEMETABLEID',
  paginate = { enabled: false, amountsPerPage: [5, 10, 20, 50] },
  filter = { enabled: false, filterOnColumnId: null },
  sensistiveDataFilter = { enabled: false, filteredColumns: [] },
  selectableRows = { enabled: false, selectedRows: [], selectId: null, onSelectRowClick: () => {}, onSelectAllClick: () => {}, actionButton: null },
  cols = [],
  data = [],
}) => {
  const { t } = useTranslation();
  const [filterText, setFilterText] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(calculateItemsPerPage(paginate, data));
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [pageBtns, setpageBtns] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [orderAsc, setOrderAsc] = useState(false);
  const [sensitiveChecked, setSensitiveDataChecked] = useState(false);

  if (data !== null && !data) data = [];

  if (data && data.length > 0 && !filter.filterOnColumnId) {
    filter = {
      ...filter,
      filterOnColumnId: Object.keys(data[0])[0],
      text: t('table.filter_by') + `${Object.keys(data[0])[0]}...`,
    };
  }

  const searchesFound = data && data.filter((row) => row[filter.filterOnColumnId].toString().includes(filterText));

  useEffect(() => {
    if (data && data.length > 0) setItemsPerPage(calculateItemsPerPage(paginate, data));
  }, [paginate, data]);

  useEffect(() => {
    const sortData = () => {
      if (orderAsc) {
        data.sort(function (a, b) {
          return a[orderBy].toString().localeCompare(b[orderBy]);
        });
      } else {
        data.sort(function (a, b) {
          return b[orderBy].toString().localeCompare(a[orderBy]);
        });
      }
    };

    const update_table = () => {
      const buildRow = (rowData) => {
        let newRow = [];

        if (selectableRows.enabled) {
          const id = rowData[selectableRows.selectId];
          newRow.push(
            <td>
              <input
                type="checkbox"
                data-testid="check"
                id={id}
                onChange={selectableRows.onSelectRowClick}
                checked={selectableRows.selectedRows.some((row) => id === row[selectableRows.selectId])}
              />
            </td>
          );
        }

        cols.forEach((col, index) => {
          if (col.data) {
            if (sensistiveDataFilter.enabled && !sensitiveChecked && sensistiveDataFilter.filteredColumns.includes(col.id)) {
              newRow.push(<td key={col.id}>{`******${sensistiveDataFilter.suffix ? sensistiveDataFilter.suffix : ''}`}</td>);
            } else {
              newRow.push(<td key={col.id}>{rowData[col.id]}</td>);
            }
          } else if (!col.data && rowData[col.id]) {
            newRow.push(<td key={t('table.special_data') + index}>{rowData[col.id]}</td>);
          } else {
            newRow.push(<td key={t('table.not_data') + index}>{t('table.not_data')}</td>);
          }
        });

        return (
          <tr data-testid={'row-data'} key={selectableRows.enabled ? rowData[selectableRows.selectId].toString() : Object.values(rowData).toString()}>
            {newRow}
          </tr>
        );
      };

      let newRows = [];
      const perPage = parseInt(itemsPerPage);
      if (orderBy) sortData();

      data.forEach((row) => {
        if (newRows.length < searchesFound.length) {
          if (filter.enabled) {
            if (filterText === '' || row[filter.filterOnColumnId].toString().includes(filterText)) {
              newRows.push(buildRow(row));
            }
          } else {
            newRows.push(buildRow(row));
          }
        }
      });

      const offset = {
        start: (page - 1) * perPage,
        end: (page - 1) * perPage + perPage,
      };

      newRows = newRows.slice(offset.start, offset.end);

      if (!isEqual(newRows, rows)) setRows(newRows);
    };

    if (data) update_table();
  }, [filterText, itemsPerPage, orderAsc, orderBy, page, sensitiveChecked, data, cols, rows, searchesFound, selectableRows, filter, sensistiveDataFilter, t]);

  useEffect(() => {
    const update_headers = () => {
      let newHeaders = [];

      if (selectableRows.enabled) {
        newHeaders.push(
          <th>
            {
              <input
                type="checkbox"
                data-testid="check-all"
                id="checkAll"
                checked={rows.length === selectableRows.selectedRows.length}
                onChange={() => {
                  const currentRowsData = data ? data.filter((allRow) => rows.some((currentRow) => currentRow.key === allRow[selectableRows.selectId])) : [];
                  return selectableRows.onSelectAllClick(currentRowsData);
                }}
              />
            }
          </th>
        );
      }

      cols.forEach((col, index) => {
        newHeaders.push(
          <th key={col.displayName + index} data-testid="column-data">
            {col.displayName}
            {col.orderable && (
              <div
                style={{ display: 'inline' }}
                data-testid="sort-button"
                onClick={() => {
                  changeOrder(col.id);
                }}
              >
                {orderAsc ? (
                  <FontAwesomeIcon data-testid="asc-caret" className={'toggleSort'} icon={faCaretUp} />
                ) : (
                  <FontAwesomeIcon data-testid="desc-caret" className={'toggleSort'} icon={faCaretDown} />
                )}
              </div>
            )}
          </th>
        );
      });
      setHeaders(newHeaders);
    };

    update_headers();
    // eslint-disable-next-line
  }, [orderAsc, orderBy, rows, page, selectableRows.enabled]);

  useEffect(() => {
    const update_pageBtns = () => {
      let newBtns = [];
      if (paginate.enabled && data && data.length > 0) {
        const neededPages = Math.ceil(searchesFound.length / itemsPerPage);
        if (neededPages > 1) {
          for (let newPage = 0; newPage < neededPages; newPage++) {
            const SELECTED = newPage + 1 === page;
            const FIRST = newPage === 0;
            const LAST = newPage === neededPages - 1;
            const SIDES = page - 1 === newPage + 1 || page + 1 === newPage + 1;
            const UNIMPORTANT = page - 2 === newPage + 1 || page + 2 === newPage + 1;

            if (SELECTED) {
              newBtns = pushSelectedPage(newBtns, newPage + 1);
            } else if (FIRST || LAST || SIDES) {
              newBtns = pushUnselectedPage(newBtns, newPage + 1);
            } else if (UNIMPORTANT) {
              newBtns.push(
                <p key={'unimportant' + newPage + 1} className="bottomSection__unselected">
                  {t('table.dotdot')}
                </p>
              );
            }
          }
        }
      }
      if (!isEqual(newBtns, pageBtns)) setpageBtns(newBtns);
    };

    if (data) update_pageBtns();
    // eslint-disable-next-line
  }, [filterText, itemsPerPage, page, data]);

  const changeOrder = (id) => {
    if (orderBy === id) {
      setOrderAsc(!orderAsc);
    } else {
      setOrderBy(id);
      setOrderAsc(true);
    }
  };

  const pushSelectedPage = (newBtns, newPage) => {
    newBtns.push(
      <p key={newPage} data-testid="selected_table_page" className="bottomSection__selected">
        {newPage}
      </p>
    );
    return newBtns;
  };

  const pushUnselectedPage = (newBtns, newPage) => {
    newBtns.push(
      <p key={newPage} data-testid="unselected_table_page" className="bottomSection__unselected" onClick={() => setPage(newPage)}>
        {newPage}
      </p>
    );
    return newBtns;
  };

  const renderFilter = () => {
    return (
      <input
        className="tableInput"
        placeholder={filter.text}
        onChange={(e) => {
          setFilterText(e.target.value);
          setPage(1);
        }}
        data-testid="filter-bar"
      />
    );
  };

  const renderPaginate = () => {
    let options = [];

    if (paginate.enabled) {
      for (let option of paginate.amountsPerPage) {
        options.push(
          <option key={option} value={option}>
            {option}
          </option>
        );
      }
    }

    return (
      <p className="tableHeaderText">
        <select
          className="tableHeaderOption"
          data-testid="rows-selector"
          onChange={(e) => {
            setItemsPerPage(e.target.value);
            setPage(1);
          }}
          value={itemsPerPage}
        >
          {options}
        </select>
        {t('table.rows_per_page')}
      </p>
    );
  };

  const renderSensitiveDataFilter = () => {
    return (
      <p className={'tableHeaderText'}>
        <input
          type={'checkbox'}
          placeholder={filter.text}
          className={'tableHeaderOption'}
          onChange={(e) => {
            setSensitiveDataChecked(!sensitiveChecked);
          }}
          data-testid="sensitiveDataFilter"
          value={sensitiveChecked}
        />
        {t('table.reveal_personal_data')}
      </p>
    );
  };

  const renderTopSection = () => {
    return (
      <div id="topSection" data-testid="topSection" className={classNames({ between: filter.enabled }, { right: !filter.enabled })}>
        {filter.enabled && renderFilter()}
        {(paginate.enabled || sensistiveDataFilter.enabled || selectableRows.enabled) && (
          <div className={'tableHeaderOptions'}>
            {selectableRows.enabled && selectableRows.actionButton}
            {paginate.enabled && renderPaginate()}
            {sensistiveDataFilter.enabled && renderSensitiveDataFilter()}
          </div>
        )}
      </div>
    );
  };

  const invalidData = (data) => {
    return !data || (data && data.length === 0);
  };

  const renderTable = () => {
    // Set page back to 1 following a delete and no results left on page
    if (!invalidData(data) && rows && rows.length === 0 && page > 1) setPage(1);
    return (
      <>
        <table id={tableId} data-testid={tableId}>
          <thead>
            <tr key={'headers' + Math.random()}>{headers}</tr>
          </thead>
          {<tbody>{rows}</tbody>}
        </table>
        {invalidData(data) && renderNoData()}
      </>
    );
  };

  const renderNoData = () => {
    return (
      <div data-testid="table-nodata" className="cogNoData">
        {data === null ? (
          <>
            <ReactLoading data-testid="loading-spinner" type={'bars'} />
            {t('table.fetching_data')}
          </>
        ) : (
          t('table.no_matching_records')
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      {renderTopSection()}
      {renderTable()}
      {paginate.enabled && (
        <div id="bottomSection" data-testid="bottomSection" className={'bottomSection bottomSection--right'}>
          {pageBtns}
        </div>
      )}
    </React.Fragment>
  );
};

export default Table;
