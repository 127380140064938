const DEVELOPMENT = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' || process.env.REACT_APP_ALPHA === 'true';
const ENDPOINT_URL = process.env.REACT_APP_ENDPOINT_URL || 'http://localhost:3001';
const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL || 'http://localhost:3000';
const REQUIRE_AUTH = process.env.NODE_ENV !== 'test';
const KOALA_CLIENT_ID = process.env.REACT_APP_KOALA_CLIENT_ID || '754669777347149844';
const ACCEPTABLE_TEXT_FILTER_TYPES = ['risky', 'banned'];
const ACCEPTABLE_TEXT_FILTER_IGNORES = ['user', 'channel'];
const ADD_KOALA_REDIRECT_URI = `https://discord.com/oauth2/authorize?client_id=${KOALA_CLIENT_ID}&scope=bot%20applications.commands&permissions=8&redirect_url=${FRONTEND_URL}&response_type=code`;
const DISCORD_SUPPORT_LINK = 'https://discord.gg/5etEjVd';
const TWITTER_LINK = 'https://twitter.com/koalabotuk';

const EXTENSION_NAMES = {
  announce: 'Announce',
  colourRole: 'ColourRole',
  reactForRole: 'ReactForRole',
  textFilter: 'TextFilter',
  verify: 'Verify',
  vote: 'Vote',
  twitchAlert: 'TwitchAlert',
};

module.exports = {
  DEVELOPMENT,
  REQUIRE_AUTH,
  ENDPOINT_URL,
  FRONTEND_URL,
  KOALA_CLIENT_ID,
  ACCEPTABLE_TEXT_FILTER_TYPES,
  ACCEPTABLE_TEXT_FILTER_IGNORES,
  ADD_KOALA_REDIRECT_URI,
  DISCORD_SUPPORT_LINK,
  TWITTER_LINK,
  EXTENSION_NAMES,
};
