import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Base from './components/Base';
import Dashboard from './components/Dashboard';
import Verify from './components/Verify';
import SocialAlert from './components/SocialAlert';
import Settings from './components/Settings';
import TwitchAlert from './components/TwitchAlert';
import Landing from './components/Landing';
import Documentation from './components/Documentation';
import TextFilter from './components/TextFilter';
import Announce from './components/Announce';
import ReactForRole from './components/ReactForRole';
import ColourRole from './components/ColourRole';
import Voting from './components/Voting';
import Error from './components/Error';
import { DEVELOPMENT } from './utils/common.js';
import DocsHome from './components/DocsHome';
import About from './components/About';
import Support from './components/Support';

const DEVELOPMENT_ROUTES = [
  <Route
    exact
    path="/dashboard"
    element={
      <>
        <Base withSidebar withAuthentication>
          <Dashboard />
        </Base>
      </>
    }
  />,
  <Route
    path="/dashboard/settings"
    element={
      <>
        <Base withSidebar withAuthentication>
          <Settings />
        </Base>
      </>
    }
  />,
  <Route
    exact
    path="/dashboard/verify"
    element={
      <>
        <Base withSidebar withAuthentication>
          <Verify />
        </Base>
      </>
    }
  />,
  <Route
    path="/dashboard/twitch"
    element={
      <>
        <Base withSidebar withAuthentication>
          <TwitchAlert />
        </Base>
      </>
    }
  />,
  <Route
    path="/dashboard/socialalert"
    element={
      <>
        <Base withSidebar withAuthentication>
          <SocialAlert />
        </Base>
      </>
    }
  />,
  <Route
    path="/dashboard/textfilter"
    element={
      <>
        <Base withSidebar withAuthentication>
          <TextFilter />
        </Base>
      </>
    }
  />,
  <Route
    path="/dashboard/voting"
    element={
      <>
        <Base withSidebar withAuthentication>
          <Voting />
        </Base>
      </>
    }
  />,
  <Route
    path="/dashboard/reactforrole"
    element={
      <>
        <Base withSidebar withAuthentication>
          <ReactForRole />
        </Base>
      </>
    }
  />,
  <Route
    path="/dashboard/colourrole"
    element={
      <>
        <Base withSidebar withAuthentication>
          <ColourRole />
        </Base>
      </>
    }
  />,
  <Route
    path="/dashboard/announce"
    element={
      <>
        <Base withSidebar withAuthentication>
          <Announce />
        </Base>
      </>
    }
  />,
];

const PRODUCTION_ROUTES = [
  <Route exact path="/" element={<Landing />} />,
  <Route
    path="/documentation/:cogName"
    element={
      <>
        <Base withDocsBar>
          <Documentation />
        </Base>
      </>
    }
  />,
  <Route
    path="/documentation/"
    element={
      <>
        <Base withDocsBar>
          <DocsHome />
        </Base>
      </>
    }
  />,
];

const ERROR_ROUTES = [
  <Route
    path="/*"
    element={
      <>
        <Base>
          <Error />
        </Base>
      </>
    }
  />,
];

const ABOUT_ROUTES = [
  <Route
    path="/about"
    element={
      <>
        <Base>
          <About />
        </Base>
      </>
    }
  />,
];

const SUPPORT_ROUTES = [
  <Route
    path="/support"
    element={
      <>
        <Base>
          <Support />
        </Base>
      </>
    }
  />,
];

export const routes = (dev_routes = DEVELOPMENT) => {
  return [PRODUCTION_ROUTES, dev_routes && DEVELOPMENT_ROUTES, ERROR_ROUTES, ABOUT_ROUTES, SUPPORT_ROUTES];
};

const App = () => {
  return (
    <Router>
      <Routes>{routes()}</Routes>
    </Router>
  );
};

export default App;
