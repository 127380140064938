import axios from 'axios';
import { ENDPOINT_URL } from '../utils/common';

export const koalaGET = async (url) => {
  return await axios.get(`${ENDPOINT_URL}/api` + url, { withCredentials: true });
};

export const koalaPOST = async (url, body) => {
  return await axios.post(`${ENDPOINT_URL}/api` + url, body, { withCredentials: true });
};

export const koalaPUT = async (url, body) => {
  return await axios.put(`${ENDPOINT_URL}/api` + url, body, { withCredentials: true });
};

export const koalaDELETE = async (url) => {
  return await axios.delete(`${ENDPOINT_URL}/api` + url, { withCredentials: true });
};

export const koalaDELETEMANY = async (url, body) => {
  return await axios.delete(`${ENDPOINT_URL}/api` + url, { data: body, withCredentials: true });
};
