import classNames from 'classnames';

import React, { useEffect, useRef, useState } from 'react';
import '../styling/tabs.scss';

const Tabs = ({ tabTitles, selectedTab, onChange, children }) => {
  const [tabs, updateTabs] = useState([]);
  const [content, updateContent] = useState([]);
  const [titles, updateTitles] = useState([]);
  const [selected, updateSelected] = useState(0);
  const onChangeTab = useRef((sel) => updateSelected(sel));

  useEffect(() => {
    if (onChange) onChangeTab.current = onChange;
  }, [onChange]);

  // drive selected if selectedTab supplied
  useEffect(() => {
    if (selectedTab) updateSelected(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (tabTitles) updateTitles(tabTitles);
  }, [tabTitles]);

  useEffect(() => {
    let newTabs = [];
    for (let index = 0; index < children.length; index++) {
      newTabs.push(
        <div data-testid="tab" key={`tab_${index}`} className={classNames('tabs__tab', { selected: selected === index })} onClick={() => onChangeTab.current(index)}>
          {titles[index] ? titles[index] : `Tab ${index + 1}`}
        </div>
      );
    }

    updateTabs(newTabs);
  }, [selected, titles, onChangeTab, children.length]);

  useEffect(() => {
    let newContent = [];
    children.forEach((child, index) => {
      if (selected === index) {
        newContent.push(child);
      }
    });

    updateContent(newContent);
  }, [selected, children]);

  return (
    <>
      <section data-testid="tabs" className="tabs">
        {tabs}
      </section>
      <div data-testid="tab-content">{content}</div>
    </>
  );
};

export default Tabs;
