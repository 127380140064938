import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../../src/locales/en.json';
i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    lng: 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    resources: {
      en: {
        translation: translationEN,
      },
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
