import React, { useState, useEffect, useContext, useRef } from 'react';
import '../styling/settings.scss';
import { DiscordContext } from '../context/discord_context';
import { useTranslation } from 'react-i18next';

import CogSection from './CogSection';
import Button from './Button';
import Modal from './Modal';

import { ENDPOINT_URL, ADD_KOALA_REDIRECT_URI, DISCORD_SUPPORT_LINK } from '../utils/common';

import { faSignOutAlt, faPlusCircle, faInbox, faComment } from '@fortawesome/free-solid-svg-icons';
import { koalaGET, koalaPUT } from '../utils/rest';
import { has } from 'lodash';

const Settings = () => {
  const { t } = useTranslation();
  const discordContext = useContext(DiscordContext);
  const [selectedGuild, updateSelectedGuild] = useState('');
  const [openedModal, updateOpenedModal] = useState(false);
  const [modalValues, updateModalValues] = useState({
    title: '',
    danger: false,
    submitText: null,
    requestType: null,
  });
  const [newWelcomeMessage, updateNewWelcomeMessage] = useState('');
  const [modalError, updateModalError] = useState(null);
  const mounted = useRef(false);

  useEffect(() => {
    if (has(discordContext, 'discordDetails.selectedGuild.id')) {
      updateSelectedGuild(discordContext.discordDetails.selectedGuild.id);
      koalaGET(`/welcome_message_view?guild_id=${selectedGuild}`)
        .then((response) => {
          if (response.status === 200) {
            updateNewWelcomeMessage(response.data.welcome_message);
          }
        })
        .catch((e) => {
          updateNewWelcomeMessage(t('settings.default_message'));
        });
    }
  }, [discordContext, selectedGuild, t]);

  const buildModal = ({ title, requestType, danger = false, submitText }) => {
    updateModalValues({
      title: title,
      requestType: requestType,
      danger: danger,
      submitText: submitText,
    });
  };
  useEffect(() => {
    if (mounted.current) {
      updateOpenedModal(true);
    } else {
      mounted.current = true;
    }
  }, [modalValues]);

  return (
    <div data-testid="settings">
      <Modal
        data-testid="welcome-modal"
        open={openedModal}
        title={modalValues.title}
        onCancel={() => updateOpenedModal(false)}
        onSubmit={() => {
          koalaPUT(`/welcome_message_update`, {
            guild_id: selectedGuild,
            welcome_message: newWelcomeMessage,
          })
            .then((response) => {
              updateOpenedModal(false);
              if (response.status === 200) {
                updateModalError('');
                updateOpenedModal(false);
              }
            })
            .catch((err) => {
              if (err.response.status === 400) {
                updateModalError(t('settings.error_400'));
              } else {
                updateModalError(t('settings.error', { errorStatus: err.response.status }));
              }
            });
        }}
        fields={
          <>
            {(modalValues.requestType === 'POST' || modalValues.requestType === 'PUT') && (
              <>
                <label className="modal__label">{t('settings.new_message')}</label>
                <textarea
                  placeholder={newWelcomeMessage}
                  name="custom_message"
                  cols="40"
                  rows="5"
                  className="modal__extendedInput"
                  data-testid="settings-welcome-field"
                  type="text"
                  value={newWelcomeMessage}
                  onChange={(e) => updateNewWelcomeMessage(e.target.value)}
                />
                <p data-testid="modal-error">{modalError}</p>
              </>
            )}
          </>
        }
        danger={modalValues.danger}
        submitText={modalValues.submitText}
      />

      <CogSection name="Settings" style={{ marginTop: '40px' }}>
        <div data-testid="settings-grid" className="settings-grid">
          <Button data-testid="add-koala" type={'settings'} text={t('settings.add_koala')} icon={faPlusCircle} onClick={() => window.location.assign(ADD_KOALA_REDIRECT_URI)} />
          <Button data-testid="support-discord" type={'settings'} text={t('settings.support')} icon={faComment} onClick={() => window.location.assign(DISCORD_SUPPORT_LINK)} />
          <Button
            data-testid="welcome-button"
            type={'settings'}
            text={t('settings.welcome_message')}
            icon={faInbox}
            onClick={() =>
              buildModal({
                title: t('settings.welcome_title'),
                requestType: 'PUT',
                danger: false,
                submitText: t('settings.update'),
              })
            }
          />
          <Button
            data-testid="logout"
            type={'settings'}
            text={t('settings.logout')}
            icon={faSignOutAlt}
            onClick={() => window.location.assign(`${ENDPOINT_URL}/api/auth/logout`)}
          />
        </div>
      </CogSection>
    </div>
  );
};

export default Settings;
