import React, { useContext, useState, useEffect } from 'react';
import '../styling/home.css';
import { Timeline } from 'react-twitter-widgets';
import { ThemeContext } from '../context/theme';
import { DiscordContext } from '../context/discord_context';
import { EXTENSION_NAMES } from '../utils/common';

import { has } from 'lodash';
import { koalaDELETE, koalaGET, koalaPOST } from '../utils/rest';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const defaultExtensionValues = {
  [EXTENSION_NAMES.announce]: false,
  [EXTENSION_NAMES.colourRole]: false,
  [EXTENSION_NAMES.reactForRole]: false,
  [EXTENSION_NAMES.textFilter]: false,
  [EXTENSION_NAMES.verify]: false,
  [EXTENSION_NAMES.vote]: false,
  [EXTENSION_NAMES.twitchAlert]: false,
};

const Dashboard = () => {
  const { t } = useTranslation();

  const theme = useContext(ThemeContext);
  const discordContext = useContext(DiscordContext);
  const [selectedGuild, updateSelectedGuild] = useState('');
  const [guildMembers, updateGuildMembers] = useState(0);
  const [guildOnlineMembers, updateGuildOnlineMembers] = useState(0);
  const [extensionError, updateExtensionError] = useState('');
  const [extensions, updateExtensions] = useState(defaultExtensionValues);

  useEffect(() => {
    if (has(discordContext, 'discordDetails.selectedGuild.id')) {
      updateSelectedGuild(discordContext.discordDetails.selectedGuild.id);
    }
  }, [discordContext]);

  useEffect(() => {
    if (selectedGuild !== '') {
      updateExtensions(defaultExtensionValues);

      koalaGET(`/guild_from_id?guild_id=${selectedGuild}`).then((res) => {
        if (res.data) {
          updateGuildMembers(res.data.memberCount);
        } else {
          updateGuildMembers('??');
        }
      });

      koalaGET(`/online_from_guild_id?guild_id=${selectedGuild}`).then((res) => {
        if (res.data) {
          updateGuildOnlineMembers(res.data);
        } else {
          updateGuildOnlineMembers('??');
        }
      });

      koalaGET(`/list_extensions?guild_id=${selectedGuild}`).then((res) => {
        if (res && res.data) {
          updateExtensions((prevState) => {
            const extensionIds = Object.keys(prevState);
            const enabledExtensions = { ...prevState };
            res.data.forEach((extension) => {
              if (extensionIds.some((ext) => ext === extension.extension_id)) {
                enabledExtensions[extension.extension_id] = true;
              }
            });
            return enabledExtensions;
          });
        }
      });
    }
  }, [selectedGuild]);

  const renderExtensionPill = (extension) => {
    const enabled = extensions[extension];
    return (
      <div
        data-testid={`extension-pill`}
        id={`${extension}Enabled`}
        key={extension}
        className={classNames('pill', { light: !enabled })}
        onClick={async () => {
          if (enabled) {
            await koalaDELETE(`/disable_extension?guild_id=${selectedGuild}&extension_id=${extension}`)
              .then((res) => {
                if (res && res.status === 200) {
                  const newExtensions = { ...extensions };
                  newExtensions[extension] = false;
                  updateExtensions(newExtensions);
                }
              })
              .catch((err) => {
                updateExtensionError(t('dashboard.disable_extension.error', { errorStatus: err.response.status }));
              });
          } else {
            await koalaPOST(`/enable_extension`, { guild_id: selectedGuild, extension_id: extension })
              .then((res) => {
                if (res && res.status === 200) {
                  const newExtensions = { ...extensions };
                  newExtensions[extension] = true;
                  updateExtensions(newExtensions);
                }
              })
              .catch((err) => {
                updateExtensionError(t('dashboard.enable_extension.error', { errorStatus: err.response.status }));
              });
          }
        }}
      >
        <FontAwesomeIcon className="icon" icon={enabled ? faCheck : faTimes} />
        <p>{t(`cogs.${extension}`)}</p>
      </div>
    );
  };

  const renderExtensions = () => {
    const jsx = [];
    Object.keys(extensions).forEach((extension) => {
      jsx.push(renderExtensionPill(extension));
    });
    return jsx;
  };

  // Count the amount of extensions that are enabled
  const enabledExtensions = Object.values(extensions).filter((ext) => ext).length;

  return (
    <>
      <section data-testid="dashboard" className="baseSection" id="stats">
        <div className="left">
          <div className="row">
            <div className="vertical-container start">
              <h3>{t('dashboard.total_members')}</h3>
              <p>{t('dashboard.online')}</p>
            </div>
            <div className="vertical-container end">
              <h3 data-testid="total-members">{guildMembers}</h3>
              <p data-testid="online-members">{guildOnlineMembers}</p>
            </div>
          </div>
          <div className="row">
            <div className="vertical-container start">
              <h3>{t('dashboard.enabled_extensions')}</h3>
              <p>{t('dashboard.add_more_extensions')}</p>
            </div>
            <div className="vertical-container end">
              <h3 data-testid="enabled-extensions">{enabledExtensions}</h3>
            </div>
          </div>
          <div className="row">
            <div className="vertical-container start blurredBackground">
              <h3>{t('dashboard.koala_pro')}</h3>
              <p>{t('dashboard.pro_extensions')}</p>
            </div>
            <div id="comingSoon">{t('dashboard.coming_soon')}</div>
            <div className="vertical-container end blurredBackground">
              <h3>{t('dashboard.go_pro')}</h3>
            </div>
          </div>
        </div>
        <div className="right">
          <div id="comingSoon">{t('dashboard.coming_soon')}</div>
          <img className="blurredBackground" id="exampleGraph" alt="comingSoonGraphs" src={require('../assets/img/example_graph.png')} />
        </div>
      </section>

      <section className="baseSection" id="extensions">
        <div className="title-row">
          <h3>{t('dashboard.koala_extensions')}</h3>
          <div className="buttons">
            <div className="button">
              <i className="fa fa-plus-square"></i>
            </div>
            <div className="button">
              <i className="fa fa-cog"></i>
            </div>
          </div>
        </div>
        <div data-testid="extension-pills" className="baseBorder">
          <div className="extensions-container">{renderExtensions()}</div>
          {extensionError && (
            <p data-testid={'extensions-error'} className={'extensions-error'}>
              {extensionError}
            </p>
          )}
        </div>
      </section>

      <section className="baseSection" id="updates">
        <div className="left">
          <h3>NEW TICKETS</h3>

          <div className="baseBorder scroll-container vertical-container justify-start" id="tickets">
            <div id="comingSoon">{t('dashboard.coming_soon')}</div>
            <div className="ticket blurredBackground" id="ticket">
              <div className="ticket-head">
                <div className="profile-icon">
                  <i className="fas fa-user"></i>
                </div>
                <div className="vertical-container">
                  <h3>CRISTAN BILNEY</h3>
                  <p>Just Now</p>
                </div>
              </div>
              <div className="ticket-body">
                <p>Lorem Ipsum dolor sit ametm consectetur adipising elit, sed do eiusmod tempor incidunt</p>
              </div>
            </div>
            <div className="ticket blurredBackground">
              <div className="ticket-head">
                <div className="profile-icon">
                  <i className="fas fa-user"></i>
                </div>
                <div className="vertical-container">
                  <h3>HEDY VANETTI</h3>
                  <p>1 day ago</p>
                </div>
              </div>
              <div className="ticket-body">
                <p>Lorem Ipsum dolor sit ametm consectetur adipising elit, sed do eiusmod tempor incidunt</p>
              </div>
            </div>
            <div className="ticket blurredBackground">
              <div className="ticket-head">
                <div className="profile-icon">
                  <i className="fas fa-user"></i>
                </div>
                <div className="vertical-container">
                  <h3>FRANCISCA TIREMAN</h3>
                  <p>54 days ago</p>
                </div>
              </div>
              <div className="ticket-body">
                <p>Lorem Ipsum dolor sit ametm consectetur adipising elit, sed do eiusmod tempor incidunt</p>
              </div>
            </div>
            <div className="ticket">
              <div className="ticket-head">
                <div className="profile-icon">
                  <i className="fas fa-user"></i>
                </div>
                <div className="vertical-container">
                  <h3>JOSEPH WILLIAMS</h3>
                  <p>1 year ago</p>
                </div>
              </div>
              <div className="ticket-body">
                <p>Lorem Ipsum dolor sit ametm consectetur adipising elit, sed do eiusmod tempor incidunt</p>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <h3>
            {t('dashboard.koala_updates')}
            <i className="fab fa-twitter"></i>
          </h3>
          <div className="baseBorder scroll-container">
            <Timeline
              dataSource={{
                sourceType: 'profile',
                screenName: 'KoalaBotUK',
              }}
              options={{
                theme: theme,
                id: 'profile:KoalaBotUK', // Temporary fix from https://github.com/andrewsuzuki/react-twitter-widgets/issues/45
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
