import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styling/support.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { DISCORD_SUPPORT_LINK, TWITTER_LINK } from '../utils/common.js';

const Support = () => {
  const { t } = useTranslation();

  return (
    <section data-testid="support" className="support">
      <h1 className="page_title">Contact Us</h1>
      <div className="support_container">
        <a className="contact_icon" href={DISCORD_SUPPORT_LINK}>
          <FontAwesomeIcon id="discord_icon" icon={faDiscord} />
        </a>
        <div className="text_section" id="discord_section">
          <h2>{t('support.discord')}</h2>
          <p>{t('support.discord_body')}</p>
          <a id="discord_link" data-testid="discord_support" href={DISCORD_SUPPORT_LINK}>
            {t('support.discord_link')}
          </a>
        </div>
        <a data-testid="twitter_support" className="contact_icon" href={TWITTER_LINK}>
          <FontAwesomeIcon id="twitter_icon" icon={faTwitter} />
        </a>
        <div className="text_section">
          <h3>{t('support.twitter')}</h3>
          <p>{t('support.twitter_body')}</p>
        </div>
        <FontAwesomeIcon id="email_icon" className="contact_icon" icon={faEnvelope} />
        <div className="text_section">
          <h3>{t('support.email')}</h3>
          <p>
            {t('support.email_body')}
            <span id="address">
              <a data-testid="email_business_enquiries" href={`mailto:${t('support.email_address')}`}>
                {t('support.email_address')}
              </a>
            </span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Support;
