import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styling/error.scss';
import Button from './Button';

const Error = () => {
  const { t } = useTranslation();

  return (
    <div data-testid="error" className="error">
      <h1>{t('error.error_404')}</h1>
      <h2>{t('error.cannot_find_page')}</h2>
      <a className="error__home" href="/">
        <Button type={'primary'} text={t('error.take_me_home')} />
      </a>
    </div>
  );
};

export default Error;
