import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import '../styling/base.css';
import '../styling/hamburger.css';
import '../styling/helpers.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faFont, faHome, faSearch, faThLarge, faUsers, faPoll, faThumbsUp, faPalette, faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { faTwitch } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';

const Sidebar = ({ route = 'dashboard' }) => {
  // TODO - refactor this to use useState to change active and inactive nav bar
  let navigate = useNavigate();
  const { t } = useTranslation();

  let path = useLocation().pathname;

  const [searchText, updateSearchText] = useState('');

  let routes = {
    base: `/${route}/`,
    cog: `/${route}/${route === 'documentation' ? 'koalabot' : 'settings'}`,
    verify: `/${route}/verify`,
    twitch: `/${route}/${route === 'documentation' ? 'twitchalert' : 'twitch'}`,
    socialalert: `/${route}/socialalert`,
    textfilter: `/${route}/textfilter`,
    announce: `/${route}/announce`,
    colourrole: `/${route}/colourrole`,
    reactforrole: `/${route}/${route === 'documentation' ? 'reactforrole(RFR)' : 'reactforrole'}`,
    vote: `/${route}/${route === 'documentation' ? 'vote' : 'voting'}`,
  };
  const openNavigationBar = () => {
    document.getElementById('nav').classList.add('active');
    Array.prototype.forEach.call(document.getElementsByClassName('baseHamburger'), function (el) {
      el.classList.add('active');
    });
  };

  function toggleFullNavigationBar() {
    document.getElementById('nav').classList.toggle('active');

    Array.prototype.forEach.call(document.getElementsByClassName('baseHamburger'), function (el) {
      el.classList.toggle('active');
    });
  }

  const cogs = [
    {
      name: t('sidebar.verify'),
      jsx: (
        <div className={path === routes['verify'] ? 'active' : 'page'} data-testid="sidebar-verify" onClick={() => navigate(routes['verify'])}>
          <FontAwesomeIcon className="baseIcon" icon={faCheck} />
          <p>{t('sidebar.verify')}</p>
        </div>
      ),
    },
    {
      name: route === 'documentation' ? t('sidebar.twitch_alert') : t('sidebar.twitch_notify'),
      jsx: (
        <div className={path === routes['twitch'] ? 'active' : 'page'} data-testid="sidebar-twitch" onClick={() => navigate(routes['twitch'])}>
          <FontAwesomeIcon className="baseIcon" icon={faTwitch} />
          <p>{route === 'documentation' ? t('sidebar.twitch_alert') : t('sidebar.twitch_notify')}</p>
        </div>
      ),
    },
    {
      name: t('sidebar.text_filter'),
      jsx: (
        <div className={path === routes['textfilter'] ? 'active' : 'page'} data-testid="sidebar-textfilter" onClick={() => navigate(routes['textfilter'])}>
          <FontAwesomeIcon className="baseIcon" icon={faFont} />
          <p>{t('sidebar.text_filter')}</p>
        </div>
      ),
    },
    {
      name: t('sidebar.announce'),
      jsx: (
        <div className={path === routes['announce'] ? 'active' : 'page'} data-testid="sidebar-announce" onClick={() => navigate(routes['announce'])}>
          <FontAwesomeIcon className="baseIcon" icon={faBullhorn} />
          <p>{t('sidebar.announce')}</p>
        </div>
      ),
    },
    {
      name: t('sidebar.colourrole'),
      jsx: (
        <div className={path === routes['colourrole'] ? 'active' : 'page'} data-testid="sidebar-colourrole" onClick={() => navigate(routes['colourrole'])}>
          <FontAwesomeIcon className="baseIcon" icon={faPalette} />
          <p>{t('sidebar.colourrole')}</p>
        </div>
      ),
    },
    {
      name: t('sidebar.reactforrole'),
      jsx: (
        <div className={path === routes['reactforrole'] ? 'active' : 'page'} data-testid="sidebar-reactforrole" onClick={() => navigate(routes['reactforrole'])}>
          <FontAwesomeIcon className="baseIcon" icon={faThumbsUp} />
          <p>{t('sidebar.reactforrole')}</p>
        </div>
      ),
    },
    {
      name: t('sidebar.voting'),
      jsx: (
        <div className={path === routes['vote'] ? 'active' : 'page'} data-testid="sidebar-voting" onClick={() => navigate(routes['vote'])}>
          <FontAwesomeIcon className="baseIcon" icon={faPoll} />
          <p>{t('sidebar.voting')}</p>
        </div>
      ),
    },
    {
      name: t('sidebar.social_alert'),
      jsx:
        route === 'documentation' ? (
          <></>
        ) : (
          <div className={path === routes['socialalert'] ? 'active' : 'page'} data-testid="sidebar-socialalert" onClick={() => navigate(routes['socialalert'])}>
            <FontAwesomeIcon className="baseIcon" icon={faUsers} />
            <p>{t('sidebar.social_alert')}</p>
          </div>
        ),
    },
    // Button to return to home page
    {
      name: t('sidebar.home'),
      jsx: (
        <div className={path === '/' ? 'active' : 'page'} data-testid="sidebar-home" onClick={() => navigate('/')}>
          <FontAwesomeIcon className="baseIcon" icon={faHome} />
          <p>{t('sidebar.home')}</p>
        </div>
      ),
    },
  ];

  const filteredCogs = cogs.filter((cog) => cog.name.toLowerCase().includes(searchText.toLowerCase())).map((cog) => cog.jsx);

  return (
    <nav className="baseNav" data-testid="sidebar" id="nav">
      <div className="navButton" data-testid="sidebar-hamburger" onClick={() => toggleFullNavigationBar()}>
        <div className="baseIconHamburger">
          <i className="baseHamburger">
            <span className="hamSpan"></span>
          </i>
        </div>
      </div>
      <div
        className="navButton dark"
        data-testid="sidebar-search"
        onClick={() => {
          openNavigationBar();
          setTimeout(() => {
            document.getElementById('navSearchInput').focus();
          }, 200);
        }}
      >
        <FontAwesomeIcon className="baseIcon" icon={faSearch} />
        <input type="text" name="" id="navSearchInput" data-testid="nav-search-bar" onChange={(e) => updateSearchText(e.target.value)}></input>
      </div>
      <div className={path === routes['base'] ? 'dark-active' : 'dark'} data-testid="sidebar-dashboard" onClick={() => navigate(routes['base'])}>
        <FontAwesomeIcon className="baseIcon" icon={route === 'documentation' ? faHome : faThLarge} />
        <p>{route === 'documentation' ? t('common.home') : t('common.dashboard')}</p>
      </div>
      <div className={path === routes['cog'] ? 'dark-active' : 'dark'} data-testid="sidebar-cog" onClick={() => navigate(routes['cog'])}>
        <FontAwesomeIcon className="baseIcon" icon={faCog} />
        <p>{route === 'documentation' ? t('sidebar.base') : t('common.settings')}</p>
      </div>

      {filteredCogs}
    </nav>
  );
};

export default Sidebar;
