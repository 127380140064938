import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../styling/bootstrap.scss';
import '../styling/landing.scss';

import Tabs from './Tabs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faEdit, faFlag, faGraduationCap, faLink, faPlayCircle, faTachometerAlt, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { DEVELOPMENT, ENDPOINT_URL, ADD_KOALA_REDIRECT_URI } from '../utils/common.js';

const classNames = require('classnames');

const devsPerPage = 9;

const Landing = ({ developers = require('../assets/developers.json').developers }) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [devIndex, updateDevIndex] = useState(1);
  const [selectedTab, updateSelectedTab] = useState(0);

  const renderNavBar = () => {
    return (
      <nav data-testid="landing_navbar" className="landing__nav">
        <div className="landing__nav__left">
          {DEVELOPMENT ? (
            <a data-testid="landing_login" href={`${ENDPOINT_URL}/api/auth/login`}>
              <div className="landing__btn">{t('landing.login')}</div>
            </a>
          ) : (
            <div className="landing__btn disabled">{t('landing.login')}</div>
          )}
        </div>
        <img className="landing__nav__logo" src={require('../assets/img/KoalaBotLogo-min.png')} alt="koala-logo" />
        <div className="landing__nav__right">
          {/* Change text to use translation */}
          {/* <a data-testid="landing_support" href={DISCORD_SUPPORT_LINK}>
            <div className="landing__btn">{t('landing.support')}</div>
          </a> */}
          <div data-testid="landing_supp" className="landing__btn" onClick={() => navigate('/support')}>
            {t('landing.support')}
          </div>
          <div data-testid="landing_docs" className="landing__btn" onClick={() => navigate('/documentation')}>
            {t('landing.documentation')}
          </div>
        </div>
      </nav>
    );
  };

  const renderHeader = () => {
    return (
      <>
        <section className="container">
          <div className="landing__header__row">
            <div className="col-12 col-lg-6 col-xl-5 offset-xl-1">
              <h1 className="landing__header__title">{t('common.koala')}</h1>
              {DEVELOPMENT ? (
                <a data-testid="landing_dashboard_btn" href={`${ENDPOINT_URL}/api/auth/login`}>
                  <div className="landing__btn landing__btn-border discordBtn">{t('common.dashboard')}</div>
                </a>
              ) : (
                <div className="landing__btn landing__btn-border disabled">{`${t('common.dashboard')} ${t('landing.coming_soon')}`}</div>
              )}
              <a data-testid="landing_add_koala" href={ADD_KOALA_REDIRECT_URI}>
                <div className="landing__btn landing__btn-border discordBtn">{t('landing.add_to_discord')}</div>
              </a>
            </div>
            <div className="col-md-5 col-lg-5 offset-lg-1 offset-xl-0 d-none d-lg-block phone-holder">
              <img className="landing__header__logo" src={require('../assets/img/KoalaBotLogo-min.png')} alt="koala-logo" />
            </div>
          </div>
        </section>
        {renderPartners()}
        <div className="landing__header__divider">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#666666"
              fillOpacity="1"
              d="M0,64L60,80C120,96,240,128,360,128C480,128,600,96,720,80C840,64,960,64,1080,80C1200,96,1320,128,1380,144L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            />
          </svg>
        </div>
      </>
    );
  };

  const renderFeatures = () => {
    return (
      <section data-testid="features" id="features" className="landing__features">
        <div className="container">
          <div className="landing__features__title">
            <h2>Features</h2>
            <a href="#features"> </a>
          </div>
          <div className="landing__features__items">
            <article className="col-sm-6 col-lg-4 landing__features__item">
              <FontAwesomeIcon className="landing__features__item-icon" icon={faGraduationCap} />
              <h3 className="landing__features__item-name">{t('landing.by_students_for_students')}</h3>
              <p className="landing__features__item-description">
                {t('landing.by_students_for_students_desc')}
                <br />
              </p>
            </article>
            <article className="col-sm-6 col-lg-4 landing__features__item">
              <FontAwesomeIcon className="landing__features__item-icon" icon={faUnlock} />
              <h3 className="landing__features__item-name">{t('landing.open_source')}</h3>
              <p className="landing__features__item-description">
                {t('landing.open_source_desc')}
                <br />
              </p>
            </article>
            <article className="col-sm-6 col-lg-4 landing__features__item">
              <FontAwesomeIcon className="landing__features__item-icon" icon={faTachometerAlt} />
              <h3 className="landing__features__item-name">{t('landing.online_dashboard')}</h3>
              <p className="landing__features__item-description">
                {t('landing.online_dashboard_desc')}
                <br />
              </p>
            </article>
            <article className="col-sm-6 col-lg-4 landing__features__item">
              <FontAwesomeIcon className="landing__features__item-icon" icon={faFlag} />
              <h3 className="landing__features__item-name">{t('landing.one_bot_to_rule_them_all')}</h3>
              <p className="landing__features__item-description">
                {t('landing.one_bot_to_rule_them_all_desc')}
                <br />
              </p>
            </article>
            <article className="col-sm-6 col-lg-4 landing__features__item">
              <FontAwesomeIcon className="landing__features__item-icon" icon={faEdit} />
              <h3 className="landing__features__item-name">{t('landing.society_specific_func')}</h3>
              <p className="landing__features__item-description">
                {t('landing.society_specific_func_desc')}
                <br />
              </p>
            </article>
            <article className="col-sm-6 col-lg-4 landing__features__item">
              <FontAwesomeIcon className="landing__features__item-icon" icon={faComment} />
              <h3 className="landing__features__item-name">{t('landing.feedback_suggestions')}</h3>
              <p className="landing__features__item-description">
                {t('landing.feedback_suggestions_desc')}
                <br />
              </p>
            </article>
          </div>
        </div>
      </section>
    );
  };

  const renderPartners = () => {
    return (
      <section className="landing__partners">
        <h2 className="landing__partner">{t('landing.partnered_with')} </h2> {renderBrandImage('https://www.thenuel.com', 'NUEL3-min.png')}
      </section>
    );
  };

  const renderInterviewVideo = () => {
    return (
      <section data-testid="interview" className="landing__interview">
        <div className="container">
          <FontAwesomeIcon className="landing__interview__icon" icon={faPlayCircle} />
          <h2 className="landing__interview__title">{t('landing.check_out_the_interview')}</h2>
          <div className="landing__interview__video landing__interview__video-wrapper ">
            <iframe title="interview" loading="lazy" src="https://www.youtube.com/embed/4tQZRjVlLrM" anonymous className="embed-responsive-item" width="100%" height="auto" />
          </div>
        </div>
      </section>
    );
  };

  const renderLinkType = (link) => {
    let logo = faLink;
    if (/linkedin.com/.test(link)) {
      logo = faLinkedin;
    } else if (/twitter.com/.test(link)) {
      logo = faTwitter;
    }

    return (
      <a
        data-testid={link ? 'dev-link' : 'missing-link-padding'}
        target="_blank"
        rel="noopener noreferrer"
        className={classNames('landing__people__social', { invisible: !link })}
        href={link}
      >
        <FontAwesomeIcon icon={logo} />
      </a>
    );
  };

  const getDeveloperImage = (developer) => {
    try {
      return require(`../assets/img/developer_photos/${developer.pic}-min.png`);
    } catch (e) {
      return require(`../assets/img/developer_photos/default-min.png`);
    }
  };

  const generateDevInfo = (developer, index) => {
    return (
      <article key={index} className="col-md-6 col-lg-4">
        <div className="landing__people__item">
          <img className="landing__people__pic" src={getDeveloperImage(developer)} loading="lazy" alt={`${developer.name}-profile`} data-testid="dev-image" />
          <div data-testid="dev-name" className="landing__people__name">
            {developer.name}
          </div>
          <div data-testid="dev-title" className="landing__people__title">
            {developer.title}
          </div>
          <div className="landing__people__links">
            {developer && developer.links ? (
              <>
                {developer.links.linkedin && renderLinkType(developer.links.linkedin)}
                {developer.links.twitter && renderLinkType(developer.links.twitter)}
                {developer.links.website && renderLinkType(developer.links.website)}
              </>
            ) : (
              renderLinkType()
            )}
          </div>
        </div>
      </article>
    );
  };

  const eventIndexChanged = (newIndex) => {
    updateDevIndex(newIndex);
  };
  const eventTabsChanged = (selected) => {
    updateSelectedTab(selected);
    updateDevIndex(1);
  };
  const renderPagination = (devsCount) => {
    const enumArray = [];
    const pageCount = Math.ceil(devsCount / devsPerPage);
    if (pageCount === 1) {
      return null;
    }

    for (let i = 1; i <= pageCount; i++) {
      enumArray.push(i);
    }
    return (
      <div className="container pagination">
        {enumArray.map((idx) => (
          <button className="pagination__button" onClick={() => eventIndexChanged(idx)}>
            {idx}
          </button>
        ))}
      </div>
    );
  };

  const renderDevs = () => {
    let devsTemp = [];
    let weTemp = [];

    developers.forEach((developer, index) => {
      if (developer.title === 'Work Experience 2021') {
        weTemp.push(generateDevInfo(developer, index));
      } else {
        devsTemp.push(generateDevInfo(developer, index));
      }
    });

    const devs = devsTemp.slice((devIndex - 1) * devsPerPage, devIndex * devsPerPage);
    const we = weTemp.slice((devIndex - 1) * devsPerPage, devIndex * devsPerPage);

    return (
      <section data-testid="devs" id="creators" className="container developer-tabs">
        <div key="a" className="landing__people">
          <a href="#creators">
            <h2 className="landing__people__header">{t('landing.the_team')}</h2>
          </a>

          <Tabs tabTitles={['Developers', 'Work Experience Summer 2021']} onTabChange={eventTabsChanged} selectedTab={selectedTab}>
            <div data-testid="maindevs" className="landing__people__items">
              {devs}
            </div>
            <div data-testid="workexpdevs" className="landing__people__items">
              {we}
            </div>
          </Tabs>
          {renderPagination(selectedTab === 0 ? devsTemp.length : weTemp.length)}
        </div>
      </section>
    );
  };

  const renderBrandImage = (link, image) => {
    return (
      <a target="_blank" rel="noopener noreferrer" href={link}>
        <img alt="brand" className="landing__brands-logo" src={require(`../assets/img/${image}`)} />
      </a>
    );
  };

  const renderBrands = () => {
    return (
      <section data-testid="brands" className="landing__brands">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#b3b3b3"
            fillOpacity="1"
            d="M0,128L48,144C96,160,192,192,288,202.7C384,213,480,203,576,192C672,181,768,171,864,165.3C960,160,1056,160,1152,165.3C1248,171,1344,181,1392,186.7L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
          />
        </svg>
        <div className="landing__brands__logos">
          {renderBrandImage('https://www.thenuel.com', 'NUEL-min.png')}
          {renderBrandImage('http://svge.uk', 'SVGE_LOGO-min.png')}
          {renderBrandImage('https://discord.gg/SwanseaGG', 'Swansea-min.png')}
          {renderBrandImage('https://www.thestudentsunion.co.uk/soc/uweesportssociety', 'UWE-min.png')}
        </div>
      </section>
    );
  };

  const renderFooterLink = (link, type) => {
    return (
      <a className="landing__footer__socials-social" target="_blank" rel="noopener noreferrer" href={link} data-testid="dev_social_link">
        <FontAwesomeIcon icon={type} />
      </a>
    );
  };

  const renderFooter = () => {
    return (
      <footer data-testid="footer" className="landing__footer">
        <section className="landing__footer__socials">
          {renderFooterLink('https://twitter.com/KoalaBotUK', faTwitter)}
          {renderFooterLink('http://discord.koalabot.uk', faDiscord)}
          {renderFooterLink('https://github.com/KoalaBotUK', faGithub)}
          {renderFooterLink('https://linkedin.com/company/koalabotukK', faLinkedin)}
        </section>
        <section className="landing__footer__list">
          <div className="landing__footer__list-item">
            <a href="/">{t('common.home')}</a>
          </div>
          <div className={classNames('landing__footer__list-item', { disabled: !DEVELOPMENT })}>
            {DEVELOPMENT ? <a href="/dashboard">{t('common.dashboard')}</a> : `${t('common.dashboard')}`}
          </div>
          <div className={classNames('landing__footer__list-item', { disabled: !DEVELOPMENT })}>
            {DEVELOPMENT ? <a href="/about">{t('common.about')}</a> : `${t('common.about')}`}
          </div>
          {!DEVELOPMENT ? (
            ''
          ) : (
            <div className="landing__footer__list-item">
              <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/drive/folders/16sw768rXd7c52FWeXrqHt0tTz3FoqN_O?usp=sharing">
                {t('common.terms')}
              </a>
            </div>
          )}
          <div className="landing__footer__list-item">
            <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/drive/folders/16sw768rXd7c52FWeXrqHt0tTz3FoqN_O?usp=sharing">
              {t('common.privacy_policy')}
            </a>
          </div>
        </section>
        <p className="landing__footer__copyright">
          {t('common.copyright')} {new Date().getFullYear()}
        </p>
      </footer>
    );
  };
  return (
    <div data-testid="landing" className="landing">
      <div>
        <header className="landing__header">
          {renderNavBar()}
          {renderHeader()}
        </header>

        <section className="landing__body">
          {renderFeatures()}
          {renderInterviewVideo()}
          {renderDevs()}
        </section>
        {renderBrands()}
        {renderFooter()}
      </div>
    </div>
  );
};

export default Landing;
