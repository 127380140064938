import React, { useState, useEffect, useRef, useContext } from 'react';
import '../styling/home.css';
import { DiscordContext } from '../context/discord_context';

import CogSection from './CogSection';
import Table from './Table';
import Button from './Button';
import Modal from './Modal';
import { faFont } from '@fortawesome/free-solid-svg-icons';

import { has } from 'lodash';
import { ACCEPTABLE_TEXT_FILTER_TYPES } from '../utils/common';
import { koalaGET, koalaPOST, koalaDELETE, koalaDELETEMANY } from '../utils/rest';
import { EXTENSION_NAMES } from '../utils/common';
import { useTranslation } from 'react-i18next';

const TextFilterFilteredWords = () => {
  const { t } = useTranslation();

  const [extensionEnabled, updateExtensionEnabled] = useState(null);
  const [textFilterWords, updateTextFilterWords] = useState(null);
  const [openedModal, updateOpenedModal] = useState(false);
  const [modalValues, updateModalValues] = useState({
    title: '',
    danger: false,
    submitText: null,
    requestType: null,
  });
  const [modalError, updateModalError] = useState(null);

  const [newFilteredText, updateNewFilteredText] = useState('');
  const [newFilterType, updateNewFilterType] = useState('risky');
  const [newIsRegex, updateNewIsRegex] = useState(false);

  const discordContext = useContext(DiscordContext);
  const [selectedGuild, updateSelectedGuild] = useState('');

  const [selectedRows, updateSelectedRows] = useState([]);

  const tableButtonClick = () => {
    buildModal({
      title: `Delete all these rows?`,
      requestType: 'DELETEROWS',
      danger: true,
      submitText: 'Delete',
    });
  };

  const mounted = useRef(false);

  useEffect(() => {
    if (selectedGuild !== '') {
      koalaGET(`/list_extensions?guild_id=${selectedGuild}`).then((res) => {
        if (res && res.data) {
          updateExtensionEnabled(res.data.some((ext) => ext.extension_id === EXTENSION_NAMES.textFilter));
        }
      });
    }
  }, [selectedGuild]);

  useEffect(() => {
    if (has(discordContext, 'discordDetails.selectedGuild.id')) {
      updateSelectedGuild(discordContext.discordDetails.selectedGuild.id);
    }
  }, [discordContext]);

  useEffect(() => {
    const generateTextFilterData = (filtered_text, guild_id, filter_type, is_regex) => {
      return {
        filteredText: filtered_text,
        guildId: guild_id,
        filterType: filter_type,
        isRegex: is_regex,
        options: (
          <div className="tableOptions">
            <Button
              data-testid="button-delete"
              type="primary"
              danger
              size="small"
              text={t('textfilter.filteredwords.delete')}
              disabled={!extensionEnabled}
              title={!extensionEnabled && t('textfilter.not_enabled')}
              style={{ flexGrow: 1 }}
              onClick={(e) => {
                updateNewFilteredText(filtered_text);
                updateNewFilterType(filter_type);
                updateNewIsRegex(is_regex);
                buildModal({
                  title: t('textfilter.filteredwords.modal_title_delete', { filtered_text }),
                  requestType: 'DELETE',
                  danger: true,
                  submitText: t('textfilter.filteredwords.delete'),
                });
                e.stopPropagation();
              }}
            />
          </div>
        ),
      };
    };

    if ((!openedModal && selectedGuild !== '' && extensionEnabled) || extensionEnabled === false) {
      koalaGET(`/text_filter_display_words?guild_id=${selectedGuild}`).then(function (response) {
        const newTextFilterWords = [];
        if (has(response, 'data')) {
          response.data.forEach((tf) => {
            const { filter_type, filtered_text, guild_id, is_regex } = tf;
            if (ACCEPTABLE_TEXT_FILTER_TYPES.includes(filter_type)) {
              newTextFilterWords.push(generateTextFilterData(filtered_text, guild_id, filter_type, is_regex.toString()));
            } else {
              newTextFilterWords.push(generateTextFilterData(filtered_text, guild_id, 'invalid_filter_type', is_regex.toString()));
            }
          });
        }
        updateTextFilterWords(newTextFilterWords);
        updateSelectedRows([]);
      });
    }
  }, [openedModal, selectedGuild, extensionEnabled, t]);

  useEffect(() => {
    if (!openedModal) {
      updateNewFilteredText('');
      updateNewFilterType('risky');
      updateNewIsRegex(false);
      updateModalError('');
    }
  }, [openedModal]);

  useEffect(() => {
    if (mounted.current) {
      updateOpenedModal(true);
    } else {
      mounted.current = true;
    }
  }, [modalValues]);

  const buildModal = ({ title, requestType, danger = false, submitText }) => {
    updateModalValues({
      title: title,
      requestType: requestType,
      danger: danger,
      submitText: submitText,
    });
  };

  return (
    <div key="filteredwords" data-testid="textfilter-filteredwords">
      <Modal
        open={openedModal}
        title={modalValues.title}
        onCancel={() => {
          updateOpenedModal(false);
          updateSelectedRows([]);
          updateModalError(null);
        }}
        onSubmit={() => {
          if (modalValues.requestType === 'POST') {
            koalaPOST(`/text_filter_add_filtered_word`, {
              guild_id: selectedGuild,
              filtered_text: newFilteredText,
              filter_type: newFilterType,
              is_regex: newIsRegex,
            })
              .then((response) => {
                if (response.status === 200) {
                  updateModalError('');
                  updateOpenedModal(false);
                }
              })
              .catch((err) => {
                if (err.response.status === 409) {
                  updateModalError(t('textfilter.filteredwords.word_already_added'));
                } else if (err.response.status === 400) {
                  updateModalError(t('textfilter.filteredwords.no_data_supplied'));
                } else {
                  updateModalError(t('textfilter.filteredwords.error', { errorStatus: err.response.status }));
                }
              });
          }
          // else if (modalValues === 'PUT') {
          // axios.put etc.
          // }
          else if (modalValues.requestType === 'DELETE') {
            koalaDELETE(`/text_filter_remove_filtered_word?guild_id=${selectedGuild}&filtered_text=${encodeURIComponent(newFilteredText)}`)
              .then((response) => {
                if (response.status === 200) {
                  updateModalError('');
                  updateOpenedModal(false);
                }
              })
              .catch((err) => {
                if (err.response.status === 400) {
                  updateModalError(t('textfilter.filteredwords.error_400'));
                } else {
                  updateModalError(t('textfilter.filteredwords.error', { errorStatus: err.response.status }));
                }
              });
          } else if (modalValues.requestType === 'DELETEROWS') {
            const request_text = selectedRows.map((row) => {
              return `${row.guildId}${row.filteredText}`;
            });
            koalaDELETEMANY(`/text_filter_remove_selected_filtered_words`, {
              guild_id: selectedGuild,
              filtered_texts: request_text,
            })
              .then((response) => {
                if (response.status === 200) {
                  updateModalError('');
                  updateOpenedModal(false);
                }
              })
              .catch((err) => {
                if (err.response.status === 400) {
                  updateModalError(t('textfilter.filteredwords.error_400'));
                } else {
                  updateModalError(t('textfilter.filteredwords.error', { errorStatus: err.response.status }));
                }
              });
          }
        }}
        fields={
          <>
            {(modalValues.requestType === 'POST' || modalValues.requestType === 'PUT') && (
              <>
                <label className="modal__label">{t('textfilter.filteredwords.filtered_text')}</label>
                <input
                  placeholder={t('textfilter.filteredwords.enter_word_to_be_filtered')}
                  className="modal__input"
                  data-testid="text-filter-text-field"
                  type="text"
                  value={newFilteredText}
                  required={true}
                  onChange={(e) => updateNewFilteredText(e.target.value)}
                />
                <label className="modal__label">{t('textfilter.filteredwords.filter_type')}</label>
                <select className="modal__input" value={newFilterType} onChange={(e) => updateNewFilterType(e.target.value)} data-testid="text-filter-type-select">
                  <option label="Risky" value="risky">
                    {t('textfilter.filteredwords.risky')}
                  </option>
                  <option label="Banned" value="banned">
                    {t('textfilter.filteredwords.banned')}
                  </option>
                </select>
                <label className="modal__label">{t('textfilter.filteredwords.is_regex')}</label>
                <select
                  className="modal__input"
                  value={newIsRegex}
                  onChange={(e) => {
                    updateNewIsRegex(e.target.value === 'true');
                  }}
                  data-testid="text-filter-regex-select"
                >
                  <option label="True" value={true}>
                    {t('textfilter.filteredwords.true')}
                  </option>
                  <option label="False" value={false}>
                    {t('textfilter.filteredwords.false')}
                  </option>
                </select>
              </>
            )}
            {modalValues.requestType === 'DELETE' && <p className="modal__label-centered">{t('textfilter.filteredwords.delete_word')}</p>}
            {modalValues.requestType === 'DELETEROWS' && <p className="modal__label-centered">{t('textfilter.filteredwords.delete_words')}</p>}
            <p data-testid="modal-error">{modalError}</p>
          </>
        }
        danger={modalValues.danger}
        submitText={modalValues.submitText}
      />
      <CogSection name="Text Filter" style={{ marginTop: '40px' }}>
        <section className="cogSectionSubtitle">
          <Button
            data-testid="button-add-tf"
            icon={faFont}
            text={t('textfilter.filteredwords.modal_title_add')}
            disabled={!extensionEnabled || false}
            title={!extensionEnabled && t('textfilter.not_enabled')}
            onClick={() =>
              buildModal({
                title: t('textfilter.filteredwords.modal_title_add'),
                requestType: 'POST',
                danger: false,
              })
            }
          />
          <div className="cogSectionEnable">
            <p>{t('textfilter.enable')}</p>
            <label className="switch">
              <input
                type="checkbox"
                checked={extensionEnabled || false}
                data-testid="extension-switch"
                onChange={async () => {
                  if (extensionEnabled) {
                    await koalaDELETE(`/disable_extension?guild_id=${selectedGuild}&extension_id=${EXTENSION_NAMES.textFilter}`).then((res) => {
                      if (res && res.status === 200) updateExtensionEnabled(false);
                    });
                  } else {
                    await koalaPOST(`/enable_extension`, { guild_id: selectedGuild, extension_id: EXTENSION_NAMES.textFilter }).then((res) => {
                      if (res && res.status === 200) updateExtensionEnabled(true);
                    });
                  }
                }}
              />
              <span className="darkModeSlider round"></span>
            </label>
          </div>
        </section>
        <hr />
        {
          <Table
            tableId="textFilterTable"
            paginate={{
              enabled: true,
              amountsPerPage: [5, 10, 20, 50],
            }}
            filter={{
              enabled: true,
              text: t('textfilter.filteredwords.filter_by_word'),
              filterOnColumnId: 'filteredText',
            }}
            selectableRows={{
              enabled: extensionEnabled,
              selectedRows: selectedRows,
              selectId: 'filteredText',
              actionButton: (
                <Button
                  data-testid="button-delete-selected"
                  danger
                  type="regular"
                  size="small"
                  text="Delete selected rows"
                  onClick={() => tableButtonClick()}
                  disabled={selectedRows.length === 0}
                />
              ),
              onSelectRowClick: (clickedRow) => {
                let newRows = [...selectedRows];
                const index = newRows.find((row) => row.filteredText === clickedRow.target.id);
                if (index) newRows = newRows.filter((row) => row.filteredText !== clickedRow.target.id);
                else newRows.push(textFilterWords.find((row) => row.filteredText === clickedRow.target.id));
                updateSelectedRows(newRows);
              },
              onSelectAllClick: (rows) => {
                if (selectedRows.length === rows.length) updateSelectedRows([]);
                else updateSelectedRows(rows);
              },
            }}
            cols={[
              {
                orderable: true,
                displayName: t('textfilter.filteredwords.filtered_text'),
                id: 'filteredText',
                data: true,
              },
              {
                orderable: true,
                displayName: t('textfilter.filteredwords.filter_type'),
                id: 'filterType',
                data: true,
              },
              {
                orderable: true,
                displayName: t('textfilter.filteredwords.is_regex'),
                id: 'isRegex',
                data: true,
              },
              {
                orderable: false,
                displayName: t('textfilter.filteredwords.options'),
                id: 'options',
                centered: true,
                data: false,
              },
            ]}
            data={textFilterWords}
          />
        }
      </CogSection>
    </div>
  );
};

export default TextFilterFilteredWords;
