import React from 'react';
import '../styling/cogSection.css';
import '../styling/button.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TYPES = ['primary', 'secondary', 'ghost', 'settings', 'documentation'];
const SIZES = ['large', 'regular', 'small'];

const Button = ({ type = 'primary', danger = false, size = 'regular', icon, text, disabled = false, ...others }) => {
  if (!TYPES.includes(type)) type = 'primary';
  if (!SIZES.includes(size)) size = 'regular';
  if (disabled) others.onClick = null;
  return (
    <div className={'button ' + (danger ? `danger button__danger__${type} ` : `button__${type} `) + `button__${size} ` + (disabled && `button__disabled`)} {...others}>
      {icon ? <FontAwesomeIcon data-testid="button-icon" icon={icon} style={{ marginRight: '10px' }} /> : ''}
      {text}
    </div>
  );
};

export default Button;
