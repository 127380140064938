import React from 'react';
import '../styling/cogSection.css';
import '../styling/modal.scss';
import Button from './Button';
import { useTranslation } from 'react-i18next';

const Modal = ({ open = false, onCancel = () => {}, onSubmit = () => {}, title = 'Default Modal', submitText = 'Submit', danger = false, fields = null }) => {
  const { t } = useTranslation();
  return (
    open && (
      <div className="darkBackground">
        <div data-testid="modal-close-anywhere" className="modalClose" onClick={() => onCancel()} />
        <div className="modal">
          <div data-testid="modal-header" className="modal__header">
            <h1 className="modal__header-text">{title}</h1>
          </div>
          <div data-testid="modal-fields" className="modal__content">
            {fields}
          </div>
          <div data-testid="modal-footer" className="modal__footer">
            <Button data-testid="modal-cancel" type={'secondary'} text={t('modal.cancel')} onClick={() => onCancel()} />
            <Button data-testid="modal-submit" danger={danger} text={submitText} onClick={() => onSubmit()} />
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
