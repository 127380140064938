import React, { useState, useEffect, useRef, useContext } from 'react';
import '../styling/home.css';
import '../styling/modal.scss';
import { DiscordContext } from '../context/discord_context';

import CogSection from './CogSection';
import Table from './Table';
import Button from './Button';
import Modal from './Modal';

import { has } from 'lodash';
import { faTwitch } from '@fortawesome/free-brands-svg-icons';
import { koalaDELETE, koalaGET, koalaPOST, koalaPUT } from '../utils/rest';
import { useTranslation } from 'react-i18next';
import { EXTENSION_NAMES } from '../utils/common';

const TwitchAlert = () => {
  const { t } = useTranslation();
  const [twitchAlerts, updateTwitchAlerts] = useState(null);
  const [openedModal, updateOpenedModal] = useState(false);
  const [modalValues, updateModalValues] = useState({
    title: '',
    danger: false,
    submitText: null,
    requestType: null,
  });

  const [extensionEnabled, updateExtensionEnabled] = useState(null);
  const [modalError, updateModalError] = useState(null);
  const [newTwitchName, updateNewTwitchName] = useState('');
  const [newTwitchChannelId, updateNewChannelId] = useState('');
  const [newTwitchCustomMessage, updateNewTwitchCustomMessage] = useState('');
  const [newTwitchType, updateNewTwitchType] = useState('user');
  const [newTeamTwitchId, updateNewTeamTwitchId] = useState('');
  const discordContext = useContext(DiscordContext);
  const [selectedGuild, updateSelectedGuild] = useState('');
  const [guildChannels, updateGuildChannels] = useState([]);

  const mounted = useRef(false);

  useEffect(() => {
    if (selectedGuild !== '') {
      koalaGET(`/list_extensions?guild_id=${selectedGuild}`).then((res) => {
        if (res && res.data) {
          updateExtensionEnabled(res.data.some((ext) => ext.extension_id === EXTENSION_NAMES.twitchAlert));
        }
      });
      koalaGET(`/guild_channels?guild_id=${selectedGuild}`).then((res) => {
        if (res.data) {
          updateGuildChannels(res.data);
        }
      });
    }
  }, [selectedGuild]);

  useEffect(() => {
    if (has(discordContext, 'discordDetails.selectedGuild.id')) {
      updateSelectedGuild(discordContext.discordDetails.selectedGuild.id);
    }
  }, [discordContext]);

  useEffect(() => {
    const generateTwitchAlertData = (twitchStream, channel, custom_message, type, teamId) => {
      return {
        twitchStream: twitchStream,
        channel: channel ? (channel.name ? `#${channel.name}` : channel.id) : 'Loading...',
        custom_message: custom_message,
        type: type,
        options: (
          <div className="tableOptions">
            <Button
              data-testid="button-edit"
              type="secondary"
              size="small"
              text="Edit"
              disabled={!extensionEnabled}
              title={!extensionEnabled && t('twitch_alert.not_enabled')}
              style={{ flexGrow: 1 }}
              onClick={() => {
                updateNewChannelId(channel.id);
                updateNewTwitchName(twitchStream);
                updateNewTwitchType(type);
                updateNewTwitchCustomMessage(custom_message);
                buildModal({
                  title: t('twitch_alert.update_@', { twitchStream }),
                  requestType: 'PUT',
                  danger: false,
                  submitText: t('twitch_alert.update'),
                });
              }}
            />
            <Button
              data-testid="button-delete"
              type="primary"
              danger
              size="small"
              text="Delete"
              disabled={!extensionEnabled}
              title={!extensionEnabled && t('twitch_alert.not_enabled')}
              style={{ flexGrow: 1 }}
              onClick={() => {
                updateNewChannelId(channel.id);
                updateNewTwitchName(twitchStream);
                updateNewTwitchType(type);
                updateNewTeamTwitchId(teamId);
                buildModal({
                  title: t('twitch_alert.delete_@', { twitchStream }),
                  requestType: 'DELETE',
                  danger: true,
                  submitText: t('twitch_alert.delete'),
                });
              }}
            />
          </div>
        ),
      };
    };

    if ((!openedModal && selectedGuild !== '' && extensionEnabled) || extensionEnabled === false) {
      koalaGET(`/twitch_alert_display_list?guild_id=${selectedGuild}`).then(function (response) {
        const newTwitchAlerts = [];
        if (has(response, 'data.team_list')) {
          response.data.team_list.forEach((ta) => {
            const { twitch_team_name, channel_id, custom_message } = ta;
            const guildChannel = guildChannels.find((channel) => channel.id === channel_id) || { id: channel_id };
            newTwitchAlerts.push(generateTwitchAlertData(twitch_team_name, guildChannel, custom_message, 'team'));
          });
        }

        if (has(response, 'data.user_list')) {
          response.data.user_list.forEach((ta) => {
            const { twitch_username, channel_id, custom_message } = ta;

            const guildChannel = guildChannels.find((channel) => channel.id === channel_id) || { id: channel_id };
            newTwitchAlerts.push(generateTwitchAlertData(twitch_username, guildChannel, custom_message, 'user'));
          });
        }
        updateTwitchAlerts(newTwitchAlerts);
      });
    }
  }, [openedModal, selectedGuild, guildChannels, t, extensionEnabled]);

  useEffect(() => {
    if (!openedModal) {
      updateNewChannelId('');
      updateNewTwitchName('');
      updateNewTwitchCustomMessage('');
      updateNewTwitchType('user');
      updateModalError('');
    }
  }, [openedModal]);

  useEffect(() => {
    if (mounted.current) {
      updateOpenedModal(true);
    } else {
      mounted.current = true;
    }
  }, [modalValues]);

  const renderGuildChannels = () => {
    const guildChannelOptions = [];
    guildChannels.forEach((channel) => {
      guildChannelOptions.push(<option key={channel.id} label={`#${channel.name}`} value={channel.id} />);
    });
    return guildChannelOptions;
  };

  const buildModal = ({ title, requestType, danger = false, submitText }) => {
    updateModalValues({
      title: title,
      requestType: requestType,
      danger: danger,
      submitText: submitText,
    });
  };

  return (
    <div data-testid="twitch">
      <Modal
        open={openedModal}
        title={modalValues.title}
        onCancel={() => updateOpenedModal(false)}
        onSubmit={() => {
          if (modalValues.requestType === 'POST') {
            koalaPOST(`/twitch_alert_add_${newTwitchType}`, {
              guild_id: selectedGuild,
              channel_id: newTwitchChannelId,
              twitch_name: newTwitchName,
              custom_message: newTwitchCustomMessage,
            })
              .then((response) => {
                if (response.status === 200) {
                  updateModalError('');
                  updateOpenedModal(false);
                }
              })
              .catch((err) => {
                if (err.response.status === 409) {
                  updateModalError(t('twitch_alert.already_exists'));
                } else if (err.response.status === 400) {
                  updateModalError(t('twitch_alert.no_data_supplied'));
                } else {
                  updateModalError(t('twitch_alert.error', { errorStatus: err.response.status }));
                }
              });
          } else if (modalValues.requestType === 'PUT') {
            koalaPUT(`/twitch_alert_update_${newTwitchType}`, {
              guild_id: selectedGuild,
              channel_id: newTwitchChannelId,
              twitch_name: newTwitchName,
              custom_message: newTwitchCustomMessage,
            })
              .then((response) => {
                if (response.status === 200) {
                  updateModalError('');
                  updateOpenedModal(false);
                }
              })
              .catch((err) => {
                if (err.response.status === 400) {
                  updateModalError(t('twitch_alert.error_400'));
                } else {
                  updateModalError(t('twitch_alert.error', { errorStatus: err.response.status }));
                }
              });
          } else if (modalValues.requestType === 'DELETE') {
            koalaDELETE(
              `/twitch_alert_remove_${newTwitchType}?guild_id=${selectedGuild}&channel_id=${newTwitchChannelId}&twitch_${
                newTwitchType === 'team' ? 'team_' : 'user'
              }name=${newTwitchName}${newTwitchType === 'team' ? `&team_twitch_alert_id=${newTeamTwitchId}` : ''}`
            )
              .then((response) => {
                if (response.status === 200) {
                  updateModalError('');
                  updateOpenedModal(false);
                }
              })
              .catch((err) => {
                if (err.response.status === 400) {
                  updateModalError(t('twitch_alert.error_400'));
                } else {
                  updateModalError(t('twitch_alert.error', { errorStatus: err.response.status }));
                }
              });
          }
        }}
        fields={
          <>
            {(modalValues.requestType === 'POST' || modalValues.requestType === 'PUT') && (
              <>
                <label className="modal__label">{t('twitch_alert.channel_id')}</label>
                {guildChannels && guildChannels.length > 0 ? (
                  <select
                    className={modalValues.requestType === 'PUT' ? 'modal__input-disabled' : 'modal__input'}
                    value={newTwitchChannelId}
                    onChange={(e) => updateNewChannelId(e.target.value)}
                    data-testid="twitch-channel-select"
                  >
                    {newTwitchChannelId === '' && updateNewChannelId(guildChannels[0].id)}
                    {renderGuildChannels()}
                  </select>
                ) : (
                  <p data-testid="twitch-channel-no-data">{t('twitch_alert.no_channels')}</p>
                )}
                <label className="modal__label">{t('twitch_alert.twitch_name')}</label>
                <input
                  placeholder={t('twitch_alert.enter_twitch_channel_name')}
                  className={modalValues.requestType === 'PUT' ? 'modal__input-disabled' : 'modal__input'}
                  data-testid="twitch-name-field"
                  type="text"
                  value={newTwitchName}
                  required={true}
                  disabled={modalValues.requestType === 'PUT'}
                  onChange={(e) => updateNewTwitchName(e.target.value)}
                />
                <label className="modal__label">{t('twitch_alert.custom_message')}</label>
                <textarea
                  placeholder={t('twitch_alert.streamer_online')}
                  name="custom_message"
                  cols="40"
                  rows="5"
                  className="modal__extendedInput"
                  data-testid="twitch-message-field"
                  type="text"
                  value={newTwitchCustomMessage}
                  onChange={(e) => updateNewTwitchCustomMessage(e.target.value)}
                />
                <label className="modal__label">{t('twitch_alert.user_or_team')}</label>
                <select
                  className={modalValues.requestType === 'PUT' ? 'modal__input-disabled' : 'modal__input'}
                  value={newTwitchType}
                  onChange={(e) => updateNewTwitchType(e.target.value)}
                  data-testid="twitch-type-select"
                >
                  <option label="User" value="user" disabled={modalValues.requestType === 'PUT'}>
                    {t('twitch_alert.user')}
                  </option>
                  <option label="Team" value="team" disabled={modalValues.requestType === 'PUT'}>
                    {t('twitch_alert.team')}
                  </option>
                </select>
              </>
            )}
            {modalValues.requestType === 'DELETE' && <p className="modal__label-centered">{t('twitch_alert.delete_confirmation')}</p>}
            <p data-testid="modal-error">{modalError}</p>
          </>
        }
        danger={modalValues.danger}
        submitText={modalValues.submitText}
      />
      <CogSection name="Twitch Alert" style={{ marginTop: '40px' }}>
        <section className="cogSectionSubtitle">
          <Button
            data-testid="button-add-ta"
            icon={faTwitch}
            text={t('twitch_alert.add_new_alert')}
            disabled={!extensionEnabled}
            title={!extensionEnabled && t('twitch_alert.not_enabled')}
            onClick={() =>
              buildModal({
                title: t('twitch_alert.add_new_alert'),
                requestType: 'POST',
                danger: false,
              })
            }
          />
          <div className="cogSectionEnable">
            <p>{t('twitch_alert.enable')}</p>
            <label className="switch">
              <input
                type="checkbox"
                checked={extensionEnabled}
                data-testid="extension-switch"
                onChange={async () => {
                  if (extensionEnabled) {
                    await koalaDELETE(`/disable_extension?guild_id=${selectedGuild}&extension_id=${EXTENSION_NAMES.twitchAlert}`).then((res) => {
                      if (res && res.status === 200) updateExtensionEnabled(false);
                    });
                  } else {
                    await koalaPOST(`/enable_extension`, { guild_id: selectedGuild, extension_id: EXTENSION_NAMES.twitchAlert }).then((res) => {
                      if (res && res.status === 200) updateExtensionEnabled(true);
                    });
                  }
                }}
              />
              <span className="darkModeSlider round"></span>
            </label>
          </div>
        </section>
        <hr />
        {
          <Table
            tableId="twitchAlertTable"
            paginate={{
              enabled: true,
              amountsPerPage: [5, 10, 20, 50],
            }}
            filter={{
              enabled: true,
              text: t('twitch_alert.filter_by'),
              filterOnColumnId: 'twitchStream',
            }}
            cols={[
              {
                orderable: true,
                displayName: t('twitch_alert.twitch_stream'),
                id: 'twitchStream',
                data: true,
              },
              {
                orderable: true,
                displayName: t('twitch_alert.channel'),
                id: 'channel',
                data: true,
              },
              {
                orderable: true,
                displayName: t('twitch_alert.type'),
                id: 'type',
                data: true,
              },
              {
                orderable: false,
                displayName: t('twitch_alert.options'),
                id: 'options',
                centered: true,
                data: false,
              },
            ]}
            data={twitchAlerts}
          />
        }
      </CogSection>
    </div>
  );
};

export default TwitchAlert;
