import React, { useState, useEffect, useRef, useContext } from 'react';
import '../styling/home.css';
import { DiscordContext } from '../context/discord_context';

import CogSection from './CogSection';
import Table from './Table';
import Button from './Button';
import Modal from './Modal';
import { faFont } from '@fortawesome/free-solid-svg-icons';

import { has } from 'lodash';
import { koalaGET, koalaPOST, koalaDELETE } from '../utils/rest';
import { EXTENSION_NAMES } from '../utils/common';
import { useTranslation } from 'react-i18next';

const TextFilterModChannels = () => {
  const { t } = useTranslation();
  const [textFilterMods, updateTextFilterMods] = useState(null);
  const [openedModal, updateOpenedModal] = useState(false);
  const [modalValues, updateModalValues] = useState({
    title: '',
    danger: false,
    submitText: null,
    requestType: null,
  });
  const [modalError, updateModalError] = useState(null);
  const [extensionEnabled, updateExtensionEnabled] = useState(null);
  const [newChannelId, updateNewChannelId] = useState('');

  const discordContext = useContext(DiscordContext);
  const [selectedGuild, updateSelectedGuild] = useState('');

  const mounted = useRef(false);

  useEffect(() => {
    if (selectedGuild !== '') {
      koalaGET(`/list_extensions?guild_id=${selectedGuild}`).then((res) => {
        if (res && res.data) {
          updateExtensionEnabled(res.data.some((ext) => ext.extension_id === EXTENSION_NAMES.textFilter));
        }
      });
    }
  }, [selectedGuild]);

  useEffect(() => {
    if (has(discordContext, 'discordDetails.selectedGuild.id')) {
      updateSelectedGuild(discordContext.discordDetails.selectedGuild.id);
    }
  }, [discordContext]);

  useEffect(() => {
    const generateTextFilterData = (channel_id, guild_id) => {
      return {
        modChannels: channel_id,
        guildId: guild_id,
        options: (
          <div className="tableOptions">
            <Button
              data-testid="button-delete"
              type="primary"
              danger
              size="small"
              text={t('textfilter.mod_channels.delete')}
              disabled={!extensionEnabled}
              title={!extensionEnabled && t('textfilter.not_enabled')}
              style={{ flexGrow: 1 }}
              onClick={() => {
                updateNewChannelId(channel_id);
                buildModal({
                  title: t('textfilter.mod_channels.delete', channel_id),
                  requestType: 'DELETE',
                  danger: true,
                  submitText: 'Delete',
                });
              }}
            />
          </div>
        ),
      };
    };

    if ((!openedModal && selectedGuild !== '' && extensionEnabled) || extensionEnabled === false) {
      koalaGET(`/text_filter_display_mod_channels?guild_id=${selectedGuild}`).then(function (response) {
        const newTextFilterMods = [];
        if (has(response, 'data')) {
          response.data.forEach((tf) => {
            const { channel_id, guild_id } = tf;
            newTextFilterMods.push(generateTextFilterData(channel_id, guild_id));
          });
        }

        updateTextFilterMods(newTextFilterMods);
      });
    }
  }, [openedModal, selectedGuild, extensionEnabled, t]);

  useEffect(() => {
    if (!openedModal) {
      updateNewChannelId('');
    }
  }, [openedModal]);

  useEffect(() => {
    if (mounted.current) {
      updateOpenedModal(true);
    } else {
      mounted.current = true;
    }
  }, [modalValues]);

  const buildModal = ({ title, requestType, danger = false, submitText }) => {
    updateModalValues({
      title: title,
      requestType: requestType,
      danger: danger,
      submitText: submitText,
    });
  };

  return (
    <div key="modchannels" data-testid="textfilter-modchannels">
      <Modal
        open={openedModal}
        title={modalValues.title}
        onCancel={() => updateOpenedModal(false)}
        onSubmit={() => {
          if (modalValues.requestType === 'POST') {
            koalaPOST(`/text_filter_add_mod_channel`, {
              guild_id: selectedGuild,
              channel_id: newChannelId,
            })
              .then((response) => {
                if (response.status === 200) {
                  updateModalError('');
                  updateOpenedModal(false);
                }
              })
              .catch((err) => {
                if (err.response.status === 409) {
                  updateModalError(t('textfilter.mod_channels.already_added'));
                } else if (err.response.status === 400) {
                  updateModalError(t('textfilter.mod_channels.no_data_supplied'));
                } else {
                  updateModalError(t('textfilter.mod_channels.error', { errorStatus: err.response.status }));
                }
              });
          } else if (modalValues.requestType === 'DELETE') {
            koalaDELETE(`/text_filter_remove_mod_channel?guild_id=${selectedGuild}&channel_id=${encodeURIComponent(newChannelId)}`)
              .then((response) => {
                if (response.status === 200) {
                  updateModalError('');
                  updateOpenedModal(false);
                }
              })
              .catch((err) => {
                if (err.response.status === 400) {
                  updateModalError(t('textfilter.mod_channels.error_400'));
                } else {
                  updateModalError(t('textfilter.mod_channels.error', { errorStatus: err.response.status }));
                }
              });
          }
        }}
        fields={
          <>
            {(modalValues.requestType === 'POST' || modalValues.requestType === 'PUT') && (
              <>
                <label className="modal__label">{t('textfilter.mod_channels.channel_id')}</label>
                <input
                  placeholder={t('textfilter.mod_channels.enter_channel_id')}
                  className="modal__input"
                  data-testid="text-filter-number-field"
                  type="number"
                  value={newChannelId}
                  required={true}
                  onChange={(e) => updateNewChannelId(e.target.value)}
                />
              </>
            )}
            {modalValues.requestType === 'DELETE' && <p className="modal__label-centered">{t('textfilter.mod_channels.remove_confirmation')}</p>}
            <p data-testid="modal-error">{modalError}</p>
          </>
        }
        danger={modalValues.danger}
        submitText={modalValues.submitText}
      />
      <CogSection name="Mod Channels" style={{ marginTop: '40px' }}>
        <section className="cogSectionSubtitle">
          <Button
            data-testid="button-add-tf"
            icon={faFont}
            text={t('textfilter.mod_channels.add_new_channel')}
            disabled={!extensionEnabled}
            title={!extensionEnabled && t('textfilter.not_enabled')}
            onClick={() =>
              buildModal({
                title: t('textfilter.mod_channels.add_new_channel'),
                requestType: 'POST',
                danger: false,
              })
            }
          />
          <div className="cogSectionEnable">
            <p>{t('textfilter.enable')}</p>
            <label className="switch">
              <input
                type="checkbox"
                checked={extensionEnabled}
                data-testid="extension-switch"
                onChange={async () => {
                  if (extensionEnabled) {
                    await koalaDELETE(`/disable_extension?guild_id=${selectedGuild}&extension_id=${EXTENSION_NAMES.textFilter}`).then((res) => {
                      if (res && res.status === 200) updateExtensionEnabled(false);
                    });
                  } else {
                    await koalaPOST(`/enable_extension`, { guild_id: selectedGuild, extension_id: EXTENSION_NAMES.textFilter }).then((res) => {
                      if (res && res.status === 200) updateExtensionEnabled(true);
                    });
                  }
                }}
              />
              <span className="darkModeSlider round"></span>
            </label>
          </div>
        </section>
        <hr />
        {
          <Table
            tableId="textFilterModChannelsTable"
            paginate={{
              enabled: true,
              amountsPerPage: [5, 10, 20, 50],
            }}
            filter={{
              enabled: true,
              text: t('textfilter.mod_channels.filter_by'),
              filterOnColumnId: 'modChannels',
            }}
            cols={[
              {
                orderable: true,
                displayName: t('textfilter.mod_channels.mod_channels'),
                id: 'modChannels',
                data: true,
              },
              {
                orderable: false,
                displayName: t('textfilter.mod_channels.options'),
                id: 'options',
                centered: true,
                data: false,
              },
            ]}
            data={textFilterMods}
          />
        }
      </CogSection>
    </div>
  );
};

export default TextFilterModChannels;
