import React from 'react';
import '../styling/home.css';
import '../styling/base.css';
import { useTranslation } from 'react-i18next';

const SocialAlert = () => {
  const { t } = useTranslation();
  return (
    <div data-testid="socialalert">
      <div className="center-screen">
        <h2 align="center"> {t('socialalert.extension_not_supported')}</h2>
      </div>
    </div>
  );
};

export default SocialAlert;
