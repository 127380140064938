import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';

import { ThemeProvider } from '../context/theme';
import { DiscordContextProvider } from '../context/discord_context';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { openCookieBanner, acceptCookies } from '../context/cookies';
import { DEVELOPMENT, ENDPOINT_URL, ADD_KOALA_REDIRECT_URI } from '../utils/common';
import { koalaGET } from '../utils/rest';
import { get, has } from 'lodash';
import '../styling/base.css';
import '../styling/hamburger.css';
import '../styling/helpers.css';
import './i18n';
import Sidebar from './Sidebar';

import AddKoala from './AddKoala';
import { useTranslation } from 'react-i18next';

const Base = ({ withSidebar, withAuthentication, withDocsBar, children }) => {
  let navigate = useNavigate();
  const { t } = useTranslation();

  const [authenticated, updateAuthenticated] = useState(false);
  const [userHasKoala, updateUserHasKoala] = useState(null);
  const [discordDetails, updateDiscordDetails] = useState({
    user: {
      discordId: '',
      discordTag: 'loading...',
      avatar: null,
    },
    guilds: [],
    selectedGuild: null,
  });
  const shouldRenderSidebar = (!withAuthentication && (withSidebar || withDocsBar)) || (withAuthentication && withSidebar && userHasKoala);

  useEffect(() => {
    openCookieBanner();
  }, []);

  useEffect(() => {
    if (withAuthentication) {
      koalaGET(`/auth/authenticated`)
        .then((res) => {
          if (!res.data) window.location.assign(`${ENDPOINT_URL}/api/auth/discord`);
          else updateAuthenticated(res.data);
        })
        .catch((err) => {
          console.log(err);
          // Clear the discord cookie if auth fails
          document.cookie = 'discord; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          window.location.assign(`${ENDPOINT_URL}/api/auth/discord`);
        });
    }
  }, [withAuthentication]);

  useEffect(() => {
    if (withAuthentication && authenticated) {
      koalaGET(`/discord_user`).then((res) => {
        if (res) updateDiscordDetails(res.data);
      });
    }
  }, [withAuthentication, authenticated]);

  useEffect(() => {
    if (authenticated === true && discordDetails.user.discordTag !== 'loading...' && discordDetails.guilds.length > 0) {
      updateUserHasKoala(true);
    } else if (authenticated === true && discordDetails.user.discordTag !== 'loading...' && discordDetails.guilds.length === 0) {
      updateUserHasKoala(false);
    }
  }, [authenticated, discordDetails]);

  const [theme, updateTheme] = useLocalStorage('theme', 'light');

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
    }
  }, [theme]);

  const toggleDarkMode = () => {
    if (theme === 'dark') {
      updateTheme('light');
    } else {
      updateTheme('dark');
    }
  };

  const getGuildSelectOptions = (discordDetails) => {
    let guilds = [];
    if (discordDetails && discordDetails.guilds && discordDetails.guilds.length > 0) {
      discordDetails.guilds.forEach((guild) => {
        guilds.push(
          <option data-testid={'guild-option'} key={guild.id} value={guild.id}>
            {guild.name}
          </option>
        );
      });
    }
    return guilds;
  };

  const renderDarkModeButton = () => {
    return (
      <React.Fragment>
        <p>{t('base.dark_mode')}</p>
        <label className="switch">
          <input type="checkbox" onChange={() => toggleDarkMode()} checked={theme === 'dark'} data-testid="dark-switch" />
          <span className="darkModeSlider round"></span>
        </label>
      </React.Fragment>
    );
  };

  const renderHeader = () => {
    return (
      <header className="baseHeader" data-testid="header">
        <div className="headerLeftSection">
          <img
            onClick={() => (withAuthentication ? navigate('/dashboard') : navigate('/'))}
            className="headerLogo"
            alt="koala_logo"
            src={require('../assets/img/KoalaBotLogo-min.png')}
            id="headerLogo"
            data-testid="header-logo"
          />
          <a href={ADD_KOALA_REDIRECT_URI}>{t('base.add_koala')}</a>
        </div>
        <div className="headerRightSection">
          {renderDarkModeButton()}
          {DEVELOPMENT && withAuthentication && (
            <>
              {discordDetails && discordDetails.guilds && discordDetails.guilds.length > 0 ? (
                <div data-testid={'guilds'} className={'serverGuilds'}>
                  {'Server: '}
                  {discordDetails.guilds.length > 1 ? (
                    <select
                      data-testid={'guild-selector'}
                      className={'selectGuilds'}
                      name="guilds"
                      onChange={(e) =>
                        updateDiscordDetails({
                          ...discordDetails,
                          selectedGuild: discordDetails.guilds.find((guild) => guild.id === e.target.value),
                        })
                      }
                    >
                      {getGuildSelectOptions(discordDetails)}
                    </select>
                  ) : (
                    discordDetails.guilds[0].name
                  )}
                </div>
              ) : null}
              <div className="vertical-container end">
                <h4 data-testid="discord-tag">{get(discordDetails, 'user.discordTag', 'loading...')}</h4>
                <p>Alpha Tester</p>
              </div>
              <img
                alt="discord-avatar"
                className="profile-icon"
                src={
                  has(discordDetails, 'user.avatar') && discordDetails.user.avatar && discordDetails.user.discordId
                    ? `https://cdn.discordapp.com/avatars/${discordDetails.user.discordId}/${discordDetails.user.avatar}.png?size=128`
                    : 'https://cdn.discordapp.com/embed/avatars/0.png'
                }
              />
            </>
          )}
        </div>
      </header>
    );
  };

  const renderCookieBanner = () => {
    return (
      <div data-testid="cookies" id="cookieBanner" className="cookieBanner">
        <div id="cookieContainer">
          <p>
            {t('base.cookies')}{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/drive/folders/16sw768rXd7c52FWeXrqHt0tTz3FoqN_O">
              {t('base.learn_more')}
            </a>
          </p>
          <div data-testid="cookies-accept" className="button" onClick={() => acceptCookies()}>
            {t('base.accept')}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div data-testid="content-parent" className={shouldRenderSidebar ? 'withSidebar content' : 'content'}>
        {shouldRenderSidebar && <Sidebar route={withDocsBar ? 'documentation' : 'dashboard'} />}
        {renderHeader()}
        <main data-testid="main-content">
          <ThemeProvider value={theme}>
            {withAuthentication && userHasKoala ? (
              <DiscordContextProvider value={{ authenticated: authenticated, discordDetails: discordDetails }}>{children}</DiscordContextProvider>
            ) : withAuthentication && userHasKoala === null ? (
              <div className="loading">
                <ReactLoading data-testid="loading-spinner" type={'spinningBubbles'} color={theme === 'light' ? 'black' : 'white'} />
              </div>
            ) : withAuthentication && userHasKoala === false ? (
              <AddKoala />
            ) : (
              children
            )}
          </ThemeProvider>
        </main>
        <footer className="baseFooter" data-testid="footer">
          <p>
            {t('common.copyright')} {new Date().getFullYear()}
          </p>
        </footer>
      </div>
      {renderCookieBanner()}
    </>
  );
};

export default Base;
