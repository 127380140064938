import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../styling/docs.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';

const Documentation = ({ docInput = require('../submodules/KoalaBot/documentation.json') }) => {
  const { t } = useTranslation();
  const [expandedCogs, updateExpandedCogs] = useState(null);
  let { cogName } = useParams();

  let routes = {
    verify: 'verification',
    koalabot: 'base',
    announce: 'announce',
    twitchalert: 'twitch_alert',
    colourrole: 'colour_role',
    textfilter: 'text_filter',
    vote: 'voting',
    'reactforrole(rfr)': 'react_for_role',
  };

  useEffect(() => {
    if (docInput && expandedCogs === null) {
      var cogs = {};
      docInput.forEach((cog) => {
        cogs = {
          ...cogs,
          [`${cog.name.replace(/ /g, '_')}_expanded`]: false,
        };
        if (cog.name !== cog.name.toLowerCase().replace(/\s+/g, '')) {
          cog.title = cog.name;
          cog.name = cog.name.toLowerCase().replace(/\s+/g, '');
        }
      });
      updateExpandedCogs(cogs);
    }
  }, [docInput, expandedCogs]);

  const getParameters = (command) => {
    var parameters = [];
    command.parameters.forEach((parameter) => {
      parameters.push(
        parameter.required === undefined ? (
          <p key={parameter}>{parameter}</p>
        ) : parameter.required === 'yes' ? (
          <p key={parameter}>{parameter.name}</p>
        ) : (
          <em className="documentation__optional" key={command.command + parameter.name}>
            {parameter.name}
          </em>
        )
      );
    });
    return parameters;
  };

  const getCommands = (cog) => {
    var commands = [];
    cog.commands.forEach((command) => {
      const parameters = getParameters(command);
      commands.push(
        <tr className="documentation__row" id={command.command} key={command.command} data-testid="commands-row">
          <th className="documentation__text" data-testid="command-name">
            {command.command}
          </th>
          <th className="documentation__text" data-testid="command-parameter">
            {parameters}
          </th>
          <th className="documentation__text" data-testid="command-description">
            {command.description}
          </th>
          <th className="documentation__text" data-testid="command-example">
            {command.example}
          </th>
        </tr>
      );
    });
    return commands;
  };

  const expandCog = (id) => {
    const cogs = JSON.parse(JSON.stringify(expandedCogs));
    cogs[id] = !cogs[id];
    updateExpandedCogs(cogs);
  };

  const buildDocumentation = (documentationJson) => {
    var docs = {};
    documentationJson.forEach((cog) => {
      const commands = getCommands(cog);
      const commandsId = `${cog.name.replace(/ /g, '')}__expanded`;
      const setupId = `${cog.name.replace(/ /g, '')}-setup__expanded`;
      docs[cog.name] = (
        <div>
          <h1 className="documentation__title" data-testid="documentation-title">
            {cog.title}
          </h1>
          <h2 className="documentation__title" data-testid="documentation-description">
            {cog.description}
          </h2>
          <a href={`https://github.com/KoalaBotUK/KoalaBot/tree/master/koala/cogs/${routes[cog.name]}`} data-testid="documentation-source">
            <div className="documentation__btn documentation__btn-border">
              <FontAwesomeIcon className="baseIcon" icon={faGithub} />
              {t('documentation.view_source')}
            </div>
          </a>

          <div key={setupId} data-testid="expandable-doc">
            <div className="documentation__expandable" onClick={() => expandCog(setupId)} data-testid="expand-clickable">
              <FontAwesomeIcon className="documentation__caret" icon={expandedCogs[setupId] ? faCaretRight : faCaretDown} />
              <h2 className="documentation__cogname" data-testid="documentation-divider-name">
                {t('documentation.setup')}
              </h2>
              <div className="documentation__divider" />
            </div>
            <ul className={expandedCogs[setupId] ? 'documentation__hidden' : ''} data-testid="cog-commands"></ul>
          </div>

          <div key={commandsId} data-testid="expandable-doc">
            <div className="documentation__expandable" onClick={() => expandCog(commandsId)} data-testid="expand-clickable">
              <FontAwesomeIcon className="documentation__caret" icon={expandedCogs[commandsId] ? faCaretRight : faCaretDown} />
              <h2 className="documentation__cogname" data-testid="documentation-cog-name">
                {t('documentation.commands')}
              </h2>
              <div className="documentation__divider" />
            </div>
            <ul className={expandedCogs[commandsId] ? 'documentation__hidden' : ''} data-testid="cog-commands">
              <table className="documentation__table">
                <thead className="documentation__header">
                  <tr>
                    <th className="documentation__commandCol" key="header-commands">
                      {t('documentation.command')}
                    </th>
                    <th className="documentation__paramCol" key="header-parameters">
                      <p>{t('documentation.parametres')}</p>
                      <em className="documentation__optional">{t('documentation.optional')}</em>
                    </th>
                    <th className="documentation__descCol" key="header-description">
                      {t('documentation.description')}
                    </th>
                    <th className="documentation__exampleCol" key="header-example">
                      {t('documentation.example')}
                    </th>
                  </tr>
                </thead>
                <tbody data-testid="commands-body">{commands}</tbody>
              </table>
            </ul>
          </div>
        </div>
      );
    });

    return docs;
  };

  return (
    <div data-testid="documentation" className="documentation">
      {}
      {expandedCogs && docInput && buildDocumentation(docInput)[cogName.toLowerCase()] !== undefined ? (
        <>{buildDocumentation(docInput)[cogName.toLowerCase()]}</>
      ) : (
        <div className="documentation__title" data-testid="documentation-error">
          {t('documentation.documentation_not_found')}
        </div>
      )}
    </div>
  );
};

export default Documentation;
