import React from 'react';
import '../styling/home.css';
import Tabs from './Tabs';
import TextFilterFilteredWords from './TextFilterFilteredWords';
import TextFilterIgnores from './TextFilterIgnores';
import TextFilterModChannels from './TextFilterModChannels';
// import { DiscordContext } from '../context/discord_context';

const TextFilter = () => {
  // Use discord context to get information about authentication and discord details
  // const discordContext = useContext(DiscordContext);
  // console.log(discordContext);
  return (
    <Tabs tabTitles={['Filtered Words', 'Ignores', 'Mod Channels']}>
      <TextFilterFilteredWords />
      <TextFilterIgnores />
      <TextFilterModChannels />
    </Tabs>
  );
};

export default TextFilter;
