import React, { useState, useEffect, useRef, useContext } from 'react';
import '../styling/home.css';
import { DiscordContext } from '../context/discord_context';

import CogSection from './CogSection';
import Table from './Table';
import Button from './Button';
import Modal from './Modal';
import { faFont } from '@fortawesome/free-solid-svg-icons';

import { has } from 'lodash';
import { ACCEPTABLE_TEXT_FILTER_IGNORES } from '../utils/common';
import { koalaGET, koalaPOST, koalaDELETE } from '../utils/rest';
import { EXTENSION_NAMES } from '../utils/common';
import { useTranslation } from 'react-i18next';

const TextFilterIgnores = () => {
  const { t } = useTranslation();
  const [textFilterIgnores, updateTextFilterIgnores] = useState(null);
  const [openedModal, updateOpenedModal] = useState(false);
  const [modalValues, updateModalValues] = useState({
    title: '',
    danger: false,
    submitText: null,
    requestType: null,
  });

  const [extensionEnabled, updateExtensionEnabled] = useState(null);
  const [modalError, updateModalError] = useState(null);
  const [newIgnoreType, updateNewIgnoreType] = useState('channel');
  const [newIgnore, updateNewIgnore] = useState('');

  const discordContext = useContext(DiscordContext);
  const [selectedGuild, updateSelectedGuild] = useState('');

  const mounted = useRef(false);

  useEffect(() => {
    if (selectedGuild !== '') {
      koalaGET(`/list_extensions?guild_id=${selectedGuild}`).then((res) => {
        if (res && res.data) {
          updateExtensionEnabled(res.data.some((ext) => ext.extension_id === EXTENSION_NAMES.textFilter));
        }
      });
    }
  }, [selectedGuild]);

  useEffect(() => {
    if (has(discordContext, 'discordDetails.selectedGuild.id')) {
      updateSelectedGuild(discordContext.discordDetails.selectedGuild.id);
    }
  }, [discordContext]);

  useEffect(() => {
    const generateTextFilterData = (guild_id, ignore_type, ignore) => {
      return {
        guildId: guild_id,
        ignoreType: ignore_type,
        ignore: ignore,
        options: (
          <div className="tableOptions">
            <Button
              data-testid="button-delete"
              type="primary"
              danger
              size="small"
              text={t('textfilter.ignores.delete')}
              disabled={!extensionEnabled}
              title={!extensionEnabled && t('textfilter.not_enabled')}
              style={{ flexGrow: 1 }}
              onClick={() => {
                updateNewIgnoreType(ignore_type);
                updateNewIgnore(ignore);
                buildModal({
                  title: `Delete ${ignore}`,
                  requestType: 'DELETE',
                  danger: true,
                  submitText: t('textfilter.ignores.delete'),
                });
              }}
            />
          </div>
        ),
      };
    };

    if ((!openedModal && selectedGuild !== '' && extensionEnabled) || extensionEnabled === false) {
      const newTextFilterIgnores = [];
      koalaGET(`/text_filter_display_ignored?guild_id=${selectedGuild}`).then(function (response) {
        if (has(response, 'data')) {
          response.data.forEach((tf) => {
            const { ignore_type, guild_id, ignore } = tf;
            if (ACCEPTABLE_TEXT_FILTER_IGNORES.includes(ignore_type)) {
              newTextFilterIgnores.push(generateTextFilterData(guild_id, ignore_type, ignore));
            } else {
              newTextFilterIgnores.push(generateTextFilterData(guild_id, 'invalid_ignore_type', ignore));
            }
          });
        }

        updateTextFilterIgnores(newTextFilterIgnores);
      });
    }
  }, [openedModal, selectedGuild, extensionEnabled, t]);

  useEffect(() => {
    if (!openedModal) {
      updateNewIgnoreType('channel');
      updateNewIgnore('');
    }
  }, [openedModal]);

  useEffect(() => {
    if (mounted.current) {
      updateOpenedModal(true);
    } else {
      mounted.current = true;
    }
  }, [modalValues]);

  const buildModal = ({ title, requestType, danger = false, submitText }) => {
    updateModalValues({
      title: title,
      requestType: requestType,
      danger: danger,
      submitText: submitText,
    });
  };

  return (
    <div key="filteredignores" data-testid="textfilter-ignores">
      <Modal
        open={openedModal}
        title={modalValues.title}
        onCancel={() => updateOpenedModal(false)}
        onSubmit={() => {
          if (modalValues.requestType === 'POST') {
            koalaPOST(`/text_filter_new_ignore`, {
              guild_id: selectedGuild,
              ignore_type: newIgnoreType,
              ignore: newIgnore,
            })
              .then((response) => {
                if (response.status === 200) {
                  updateModalError('');
                  updateOpenedModal(false);
                }
              })
              .catch((err) => {
                if (err.response.status === 409) {
                  updateModalError(t('textfilter.ignores.channel_already_added'));
                } else if (err.response.status === 400) {
                  updateModalError(t('textfilter.ignores.no_data_supplied'));
                } else {
                  updateModalError(t('textfilter.ignores.error', { errorStatus: err.response.status }));
                }
              });
          }
          // else if (modalValues === 'PUT') {
          // axios.put etc.
          // }
          else if (modalValues.requestType === 'DELETE') {
            koalaDELETE(`/text_filter_remove_ignored?guild_id=${selectedGuild}&ignore=${newIgnore}`)
              .then((response) => {
                if (response.status === 200) {
                  updateModalError('');
                  updateOpenedModal(false);
                }
              })
              .catch((err) => {
                if (err.response.status === 400) {
                  updateModalError(t('textfilter.ignores.error_400'));
                } else {
                  updateModalError(t('textfilter.ignores.error', { errorStatus: err.response.status }));
                }
              });
          }
        }}
        fields={
          <>
            {(modalValues.requestType === 'POST' || modalValues.requestType === 'PUT') && (
              <>
                <label className="modal__label">Ignore</label>
                <input
                  placeholder={t('textfilter.ignores.enter_channel_id')}
                  className="modal__input"
                  data-testid="text-filter-number-field"
                  type="number"
                  min="0"
                  value={newIgnore}
                  required={true}
                  onChange={(e) => updateNewIgnore(e.target.value)}
                />
                <label className="modal__label">{t('textfilter.ignores.ignore_type')}</label>
                <select className="modal__input" value={newIgnoreType} onChange={(e) => updateNewIgnoreType(e.target.value)} data-testid="text-filter-type-select">
                  <option label="Channel" value="channel">
                    {t('textfilter.ignores.channel')}
                  </option>
                  <option label="User" value="user">
                    {t('textfilter.ignores.user')}
                  </option>
                </select>
              </>
            )}
            {modalValues.requestType === 'DELETE' && <p className="modal__label-centered">{t('textfilter.ignores.ignore_delete_confirmation')}</p>}
            <p data-testid="modal-error">{modalError}</p>
          </>
        }
        danger={modalValues.danger}
        submitText={modalValues.submitText}
      />
      <CogSection name="Ignored Users/Channels" style={{ marginTop: '40px' }}>
        <section className="cogSectionSubtitle">
          <Button
            data-testid="button-add-tf"
            icon={faFont}
            text={t('textfilter.ignores.add_new_ignore')}
            disabled={!extensionEnabled}
            title={!extensionEnabled && t('textfilter.not_enabled')}
            onClick={() =>
              buildModal({
                title: t('textfilter.ignores.add_new_ignore'),
                requestType: 'POST',
                danger: false,
              })
            }
          />
          <div className="cogSectionEnable">
            <p>{t('textfilter.enable')}</p>
            <label className="switch">
              <input
                type="checkbox"
                checked={extensionEnabled}
                data-testid="extension-switch"
                onChange={async () => {
                  if (extensionEnabled) {
                    await koalaDELETE(`/disable_extension?guild_id=${selectedGuild}&extension_id=${EXTENSION_NAMES.textFilter}`).then((res) => {
                      if (res && res.status === 200) updateExtensionEnabled(false);
                    });
                  } else {
                    await koalaPOST(`/enable_extension`, { guild_id: selectedGuild, extension_id: EXTENSION_NAMES.textFilter }).then((res) => {
                      if (res && res.status === 200) updateExtensionEnabled(true);
                    });
                  }
                }}
              />
              <span className="darkModeSlider round"></span>
            </label>
          </div>
        </section>
        <hr />
        {
          <Table
            tableId="textFilterIgnoresTable"
            paginate={{
              enabled: true,
              amountsPerPage: [5, 10, 20, 50],
            }}
            filter={{
              enabled: true,
              text: t('textfilter.ignores.filter_by_ignore'),
              filterOnColumnId: 'ignore',
            }}
            cols={[
              {
                orderable: true,
                displayName: t('textfilter.ignores.ignored_users'),
                id: 'ignore',
                data: true,
              },
              {
                orderable: true,
                displayName: t('textfilter.ignores.ignore_type'),
                id: 'ignoreType',
                data: true,
              },
              {
                orderable: false,
                displayName: t('textfilter.ignores.options'),
                id: 'options',
                centered: true,
                data: false,
              },
            ]}
            data={textFilterIgnores}
          />
        }
      </CogSection>
    </div>
  );
};

export default TextFilterIgnores;
