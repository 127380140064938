import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import '../styling/docs.scss';
import Button from './Button';

import { FRONTEND_URL, ADD_KOALA_REDIRECT_URI } from '../utils/common';
import { faPlusCircle, faCheck, faCog, faFont, faPoll, faThumbsUp, faPalette, faBullhorn, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { faTwitch } from '@fortawesome/free-brands-svg-icons';

const DocsHome = ({ route = 'documentation' }) => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  let routes = {
    base: `/${route}/koalabot`,
    verify: `/${route}/verify`,
    twitch_alert: `/${route}/twitchalert`,
    text_filter: `/${route}/textfilter`,
    announce: `/${route}/announce`,
    colour_role: `/${route}/colourrole`,
    react_for_role: `/${route}/reactforrole(RFR)`,
    vote: `/${route}/vote`,
  };

  let cogs = [
    ['verify', faCheck],
    ['twitch_alert', faTwitch],
    ['text_filter', faFont],
    ['announce', faBullhorn],
    ['colour_role', faPalette],
    ['react_for_role', faThumbsUp],
    ['vote', faPoll],
  ];

  const buildCogs = (cogs) => {
    var btns = [];

    cogs.forEach((item) => {
      const key = item[0];
      const icon = item[1];

      btns.push(<Button data-testid={key} type={'settings'} text={t('docshome.' + key)} icon={icon} onClick={() => navigate(routes[key])} />);
    });

    return btns;
  };

  return (
    <section data-testid="docshome" className="docshome">
      <div data-testid="docshome__container" className="docshome__container">
        <h1 className="docshome__title">{t('docshome.welcome')}</h1>

        <Button
          data-testid="add_koala"
          type={'settings'}
          style={{ display: 'inline-block', width: 'fit-content' }}
          text={t('docshome.add_koala')}
          icon={faPlusCircle}
          onClick={() => window.location.assign(ADD_KOALA_REDIRECT_URI)}
        />

        <Button
          data-testid="base"
          type={'settings'}
          style={{ display: 'inline-block', width: 'fit-content' }}
          text={t('docshome.base')}
          icon={faCog}
          onClick={() => navigate(routes['base'])}
        />

        <Button
          data-testid="landing"
          type={'settings'}
          style={{ display: 'inline-block', width: 'fit-content' }}
          text={t('docshome.landing')}
          icon={faArrowCircleLeft}
          onClick={() => window.location.assign(`${FRONTEND_URL}`)}
        />
      </div>

      <div data-testid="docshome__container" className="docshome__container">
        <h1 className="docshome__title">{t('docshome.cogs')}</h1>
        <div data-testid="docshome__grid" className="docshome__grid">
          {buildCogs(cogs)}
        </div>
      </div>
    </section>
  );
};

export default DocsHome;
